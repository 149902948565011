import React, { useState } from 'react';
import { FetchEducation } from "./Partial/fetchEducation";
import { AddDoctorEducation } from "./Partial/addDoctorEducation";
import { Link } from 'react-router-dom';
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import { SecondaryButtonInput } from '../../../mainComponent/secondaryButtonInput';
import { Modal } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import { Drawer, IconButton, Typography, useMediaQuery } from '@mui/material';

function DoctorEducation(props) {
    const { doctorId } = props;
    const [showEducation, setShowEducation] = useState(true);
    const [open, setOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:768px)')

    function handleAdd() {
        setShowEducation(!showEducation);
        setOpen(!open)
    }

    function handleRecordAdded() {
        setShowEducation(true)
        setOpen(false)
    }

    return (
        <>
            <FetchEducation doctorId={doctorId} />

            <div className="row justify-end">
                <div className="my-2 ">
                    <Link onClick={() => handleAdd()}>
                        <MainButtonInput>Add</MainButtonInput>
                    </Link>
                </div>
                <div className="m-2 ">
                    <SecondaryButtonInput onClick={props.data}>Next</SecondaryButtonInput>
                </div>
            </div>
            <>
                {isMobile ? (
                    <Drawer anchor="bottom" open={open} onClose={handleAdd}>
                        <div className='drawerTitle underline' >
                            <Typography variant="h6">Add Education</Typography>
                            <IconButton onClick={handleAdd} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div className='p-4'>
                            <AddDoctorEducation
                                doctorId={doctorId}
                                addRecords={handleRecordAdded} />
                        </div>
                    </Drawer>
                ) : (

                    <Modal show={showEducation === false} onHide={handleRecordAdded}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add Education</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <AddDoctorEducation
                                doctorId={doctorId}
                                addRecords={handleRecordAdded} />
                        </Modal.Body>
                    </Modal>
                )
                }
            </>
        </>
    )
}
export { DoctorEducation }