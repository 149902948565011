import { useState } from "react";
import { MainButtonInput } from "../mainComponent/mainButtonInput";
import { MainInput } from "../mainComponent/mainInput";
import PatientApi from "../services/PatientApi";
import { useNavigate } from "react-router-dom";
import { setNewPatientId } from "../recoil/atom/setNewPatientId";
import { setDependentId } from "../recoil/atom/setDependentId";
import { useRecoilState } from "recoil";

function ForgotMpinPatient(props) {
    const { mobile, doctorId } = props;
    const { PatientForgetMpin } = PatientApi()
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate();
    const [data, setData] = useState(
        {
            password: '',
            confirmPassword: ''
        }
    );
    const handleChange = (event) => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const password = data.password.trim();
        const confirmPassword = data.confirmPassword.trim();
        if (password === '' || confirmPassword === '') {
            setIsError(true);
        }
        else if (password === confirmPassword) {
            const bodyData = {
                mobile: mobile,
                password: password,
            }
            PatientForgetMpin(bodyData)
                .then(response => {
                    const patientId = response.data._id
                    navigate(`/appointments/${doctorId}/patientprofile/${patientId}`)
                })
        }
        else {
            setIsError(true)
        }

    };

    return (
        <div className="row full-width">
            <lable className='mb-2'>Enter MPIN</lable>
            <div className="col-md-12">
                <MainInput
                    type="password"
                    name="password"
                    maxLength={6}
                    pattern="[+-]?\d+(?:[.,]\d+)?"
                    onChange={handleChange}
                    placeholder="New MPIN"
                    required
                >
                </MainInput>
            </div>
            <lable className='mb-2'>Confirm MPIN</lable>
            <div className="col-md-12">
                <MainInput
                    type="password"
                    name="confirmPassword"
                    maxLength={6}
                    pattern="[+-]?\d+(?:[.,]\d+)?"
                    onChange={handleChange}
                    placeholder="Confirm MPIN"
                    required>
                </MainInput>
            </div>
            <div className="width25">
                <MainButtonInput onClick={handleSubmit}>Set Mpin</MainButtonInput>
            </div>
        </div>
    )

}
export { ForgotMpinPatient }