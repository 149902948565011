import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD-oJLUSgaHv1b7Sz4is71DYsEjfxzUCJA",
  authDomain: "fly4smiles-412a2.firebaseapp.com",
  projectId: "fly4smiles-412a2",
  storageBucket: "fly4smiles-412a2.firebasestorage.app",
  messagingSenderId: "314133128034",
  appId: "1:314133128034:web:c5ea7865f1bf345ddd023b",
  measurementId: "G-9ELEGBM876"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);