import './App.css';
import React from 'react';
import Header from './common/Header';
import Footer from './common/Footer';
import MainContainer from './MainContainer';
import { BrowserRouter as Router, Outlet } from "react-router-dom";
import Toaster from './doctor/Toaster';
const fbConfig = require("./firebase.config")

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <MainContainer></MainContainer>
        <Toaster />
        <Outlet />
        <Footer />
      </Router>
    </div>
  );
}
export default App;
