import React, { useEffect } from 'react';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import ReportApi from '../../../services/ReportApi';
import { useRecoilState } from 'recoil';
import { setMedicineData } from '../../../recoil/atom/setMedicineData';

const GetMedicinePriscription = (props) => {
    const { reportId } = props;
    const { getMedicinePrescriptionData } = ReportApi();
    const [showMedicineData, setShowMedicineData] = useRecoilState(setMedicineData)

    useEffect(() => {
        getMedicineData()
    }, [])

    function getMedicineData() {
        getMedicinePrescriptionData(reportId)
            .then((result) => {
                setShowMedicineData(result);
            })
    }

    return (
        <>
            {showMedicineData && showMedicineData.length > 0?
                <div className='margin_top_15'>
                    <h6 align="left" className='patientModal ml-2'>Medicine List</h6>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'><span className='patientModal'>Index</span></TableCell>
                                    <TableCell align='center'><span className='patientModal'>Medicine Name</span></TableCell>
                                    <TableCell align='center'><span className='patientModal'>Take</span></TableCell>
                                    <TableCell align='center'><span className='patientModal'>Duration</span></TableCell>
                                    <TableCell align='center'><span className='patientModal'>Mg</span></TableCell>
                                    <TableCell align='center'><span className='patientModal'>Slots</span></TableCell>
                                    <TableCell align='center'><span className='patientModal'>Notes</span></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {showMedicineData && showMedicineData.map((item, i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell align='center'>{i + 1}</TableCell>
                                            <TableCell align='center'>{item.medicineName}</TableCell>
                                            <TableCell align='center'>{item.timing}</TableCell>
                                            <TableCell align='center'>{item.days}</TableCell>
                                            <TableCell align='center'>{item.mg}</TableCell>
                                            <TableCell align='center'>
                                                {item['frequency'].map((schedule, index) => {
                                                    return (
                                                        <>{schedule.schedule}, </>
                                                    )
                                                })}
                                            </TableCell>
                                            <TableCell align='center'>{item.note ? item.note : null}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                : null}
        </>

    )


}
export default GetMedicinePriscription;