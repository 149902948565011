const MainButtonInput =(props)=>{
    return(
        <div className="form-group">     
            <input 
                type="submit" 
                className="btn_1" 
                onClick={props.onClick} 
                value={props.children}
                disabled={props.disabled}
                defaultValue={props.defaultValue}
                
                />
        </div>
    )
}

export {MainButtonInput} 