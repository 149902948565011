import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import PatientApi from "../../../services/PatientApi";
import { Wrapper } from "../../../mainComponent/Wrapper";
import { MainNav } from "../../../mainComponent/mainNav";
import { useRecoilState } from "recoil";
import UserLinks from "./uselinks";
import { setHelperData } from "../../../recoil/atom/setHelperData";
import { setDoctorId } from "../../../recoil/atom/setDoctorId";
import { FetchPatientLifestyleData } from "../../../patient/fetchPatientLifestyleData";
import { FetchPatientMedicalInfo } from "../../../patient/fetchPatientMedicalInfo";

export default function PatientData() {
    const { patientId } = useParams();
    const [fetchPatientData, setFetchPatientData] = useState([])
    const [helpersData, setHelpersData] = useRecoilState(setHelperData)
    const [doctorId, setDoctorsId] = useRecoilState(setDoctorId)
    const { patientDetailsData } = PatientApi()

    useEffect(() => {
        getAllPatientData()
    }, [])

    function getAllPatientData() {
        patientDetailsData({ patientId })
            .then((response) => {
                setFetchPatientData(response[0])
            })
    }

    return (
        <Wrapper>
            <MainNav>
                <ul className="clearfix">
                    <li>
                        <Link to={`/appointments/${doctorId}`}>
                            <i className="arrow_back backArrow" title="back button"></i>
                        </Link>
                    </li>
                    <li className='float-none' style={{ fontSize: 'inherit' }} >Patient Data</li>

                </ul>
            </MainNav>

            <div className="row ">
                <div className='width_16'>
                    <div className="dash row">
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module}
                        />
                    </div>
                </div>
                <div className='width_84'>
                    <div className="row width_100 ">
                        <div className="width-32 patientBox mt-2 ml-1 mr-1">
                            <div className="underline">
                                <div className="form_title">
                                    <h4>Patient Details</h4>
                                </div>
                            </div>
                            <div className="patientDataStyle">
                                <div>
                                    <label className="font_weight mx-2 " >Patient name :</label>
                                    {fetchPatientData.name}
                                </div>
                                <div>
                                    <label className="font_weight mx-2 ">Age :</label>
                                    {fetchPatientData.age}
                                </div>
                                <div>
                                    <label className="font_weight mx-2 ">Gender :</label>
                                    {fetchPatientData.gender}
                                </div>
                                <div>
                                    <label className="font_weight mx-2 ">Email :</label>
                                    {fetchPatientData.email}
                                </div>
                            </div>
                        </div>
                        {fetchPatientData.mobile ?
                            <>
                                <FetchPatientLifestyleData patientId={patientId} />
                                <FetchPatientMedicalInfo patientId={patientId} />
                            </>
                            : null}
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}
