import React, { useEffect } from 'react';
import ReportApi from '../../../services/ReportApi';
import { useRecoilState } from 'recoil';
import { setFetchTreatment } from '../../../recoil/atom/setFetchTreatment';

export default function GetDentalServices(props) {
    const { reportId } = props;
    const { getDentalServicesPrescription } = ReportApi();
    const [getServices, setGetServices] = useRecoilState(setFetchTreatment);
    
    useEffect(() => {
        getServicesData()
    }, [])

    const getServicesData = () => {
        getDentalServicesPrescription({ reportId })
            .then((result) => {
                setGetServices(result)
            })
    }

    return (
        <>
            {getServices &&  getServices.length > 0 ?
                    <div className='margin_top_15' align='left'>
                        <h6  className='patientModal'>List of Services</h6>
                        {getServices && getServices.map((item, i) => {
                            return (
                                <span key={i}>
                                    {item.service_name}/ fees-{item.fees}<br />
                                </span>
                            )
                        })}
                    </div>
                : null
            }
        </>
    )
}