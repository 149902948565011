
import React from "react";
import moment from 'moment';

export default function ViewInventory(props) {
    const { viewInwardData } = props

    return (
        <div className="patientDataStyle">
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2" >Item Name</label>
                </div>
                <div className="width70">
                    {viewInwardData.itemName}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Manufacturer</label>
                </div>
                <div className="width70">
                    {viewInwardData.manufacturerName}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Category</label>
                </div>
                <div className="width70">
                    {viewInwardData.category}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Subcategory</label>
                </div>
                <div className="width70">
                    {viewInwardData.subCategory}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Quantity</label>
                </div>
                <div className="width70">
                    {viewInwardData.quantity}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">SPOC</label>
                </div>
                <div className="width70">
                    {viewInwardData.spoc}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Clinic Name</label>
                </div>
                <div className="width70">
                    {viewInwardData.clinicName}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Inward Date</label>
                </div>
                <div className="width70">
                    {moment(viewInwardData.inwardDate).format("DD-MM-YYYY")}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Expiry Date</label>
                </div>
                <div className="width70">
                    {moment(viewInwardData.expiryDate).format("DD-MM-YYYY")}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Price</label>
                </div>
                <div className="width70">
                    {viewInwardData.cost}
                </div>
            </div>
        </div>
    )
}