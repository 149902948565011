import React, { useEffect, useState, useRef } from "react";
import { setHelperData } from "../../../recoil/atom/setHelperData";
import { Wrapper } from '../../../mainComponent/Wrapper';
import { useRecoilState } from "recoil";
import { Link, useParams } from 'react-router-dom';
import { MainNav } from '../../../mainComponent/mainNav';
import UserLinks from '../partial/uselinks';
import { Button, Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import LabWorkApi from "../../../services/LabWorkApi";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import Loader from '../Loader';
import { Drawer, IconButton, Typography, useMediaQuery } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import InventoryModalBox from "./InventoryModalBox";
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import { setClinicId } from "../../../recoil/atom/setClinicId";
import InventoryApi from "../../../services/InventoryApi";
import ViewInventory from "./ViewInventory";

export default function Inwards() {
    const { doctorId } = useParams();
    const [helpersData] = useRecoilState(setHelperData)
    const [inwardData, setInwardData] = useState(null)
    const [viewInwardData, setViewInwardData] = useState(null)
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0);
    const [showData, setShowData] = useState(false);
    const [viewData, setViewData] = useState(false);
    const isMobile = useMediaQuery('(max-width:  768px)')
    const [open, setOpen] = useState(false);
    const [clinicId, setClinicid] = useRecoilState(setClinicId);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const paginationRef = useRef(currentPage);
    const [activeModal, setActiveModal] = useState();
    const labRef = useRef(currentPage);
    const dateRangeRef = useRef(currentPage);
    const pageSize = 5;
    const { getInwardDetails } = InventoryApi()

    useEffect(() => {
        getInwardData();
    }, [])

    const getInwardData = () => {
        setIsLoading(true)
        getInwardDetails(doctorId)
            .then((result) => {
                setInwardData(result)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handlePageClick = (data) => {
        paginationRef.current = data.selected + 1
        setCurrentPage(data.selected + 1)
        getInwardData(data.selected + 1)
    }

    const handleDataClose = () => {
        setShowData(false)
        setOpen(false)
    }
    const handleDataShow = () => {
        setShowData(true)
        setOpen(true)
    }

    const handleViewClose = () => {
        setViewData(false)
        setOpen(false)
    }

    const handleView = (item) => {
        setViewInwardData(item)
        setViewData(true)
        setOpen(true)
    }
    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };
    const openDrawerWithModal = (modalType, item) => {
        setActiveModal(modalType);
        setViewInwardData(item)
        setDrawerOpen(true);
    };
    const renderModalContent = () => {
        switch (activeModal) {
            case 'inventorymodalbox':
                return <InventoryModalBox
                    doctorId={doctorId}
                    onSubmit={handleDataClose} />
            case 'viewinventory':
                return <ViewInventory
                    doctorId={doctorId}
                    clinicId={clinicId}
                    viewInwardData={viewInwardData}
                    onSubmit={handleViewClose}
                />
            default:
                return null;
        }
    };


    return (
        <>
            <Wrapper>
                <MainNav>
                    <div className="clearfix row">
                        <div className="width50">
                            <ul className="clearfix">
                                <li className='float-none' style={{ fontSize: 'inherit' }}>Report</li>
                            </ul>
                        </div>
                    </div>
                </MainNav>

                <div className='row'>
                    <div className='width_16'>
                        <div className='dash row'>
                            <UserLinks
                                doctorId={doctorId}
                                helperId={helpersData._id}
                                accessModule={helpersData.access_module}
                            />
                        </div>
                    </div>

                    <div className='width_84'>
                        {isLoading ?
                            <div className='loader-container'>
                                <Loader />
                            </div>
                            :
                            <div className='common_box'>
                                <div align='right '>
                                    <MainButtonInput onClick={() => isMobile ? openDrawerWithModal('inventorymodalbox') : handleDataShow()}>
                                        Add Inwards
                                    </MainButtonInput>
                                </div>
                                {inwardData && inwardData.length > 0 ?
                                    <>

                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className="font_weight" align="left">Item Name</TableCell>
                                                        <TableCell className="font_weight" align="left">Manufacturer Name</TableCell>
                                                        <TableCell className="font_weight" align="left">Expiry Date</TableCell>
                                                        <TableCell className="font_weight" align="left">Quantity</TableCell>
                                                        <TableCell className="font_weight" align="left">Cost</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {inwardData && inwardData.map((item, index) => {
                                                        return (
                                                            <TableRow key={index}>
                                                                <TableCell align="left">
                                                                    {item.itemName}
                                                                </TableCell>

                                                                <TableCell align="left">
                                                                    {item.manufacturerName}
                                                                </TableCell>

                                                                <TableCell align="left">
                                                                    {moment(item.expiryDate).format("DD-MM-YYYY")}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {item.quantity}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {item.cost}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    <Link onClick={() => isMobile ? openDrawerWithModal('viewinventory', item) : handleView(item)}>
                                                                        <VisibilityIcon style={{ fontSize: 20 }} />
                                                                    </Link>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        {/* <div className='mt-5'>
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel="Next >"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={5}
                                                pageCount={totalPages}
                                                previousLabel="< Previous"
                                                renderOnZeroPageCount={null}
                                                marginPagesDisplayed={2}
                                                containerClassName="pagination "
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                activeClassName="active"
                                                forcePage={currentPage - 1}
                                            />
                                        </div> */}
                                    </> :
                                    <div className="clinicHistory font_weight" align='center' >
                                        Inwards details are not available
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </Wrapper>
            {isMobile ? (
                <Drawer anchor="bottom" open={drawerOpen} onClose={handleDrawerToggle}>
                    <div className='drawerTitle underline' >
                        <Typography variant="h6">{()=>openDrawerWithModal('viewinventory') ? 'View Details':'Add Inward Details'}</Typography>
                        <IconButton onClick={handleDrawerToggle} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div className='p-4'>
                        {renderModalContent()}
                    </div>
                </Drawer>
            ) : (
                <>
                    <div className="modalbtn">
                        <Modal show={showData} onHide={handleDataClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add Inward Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <InventoryModalBox
                                    doctorId={doctorId}
                                    onSubmit={handleDataClose} />
                            </Modal.Body>
                        </Modal>
                    </div>
                    <Modal show={viewData} onHide={handleViewClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Inward Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ViewInventory
                                doctorId={doctorId}
                                clinicId={clinicId}
                                viewInwardData={viewInwardData}
                                onSubmit={handleViewClose}
                            />
                        </Modal.Body>
                    </Modal>
                </>
            )}
        </>
    )
}