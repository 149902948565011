import axios from 'axios';
import { API } from '../config'

export default function InventoryApi() {

    const addInward = async (doctorId, clinicId, bodyData) => {
        try {
            const result = await axios.post(`${API}/addinwarddata/${doctorId}/${clinicId}`, bodyData)
            return result.data
        }
        catch (err) {
            return err
        }
    }
    const editInwardDetails = async (doctorId, clinicId, bodyData) => {
        try {
            const result = await axios.post(`${API}/editinwarddata/${doctorId}/${clinicId}`, bodyData)
            return result.data
        }
        catch (err) {
            return err
        }
    }
    const getInwardDetails = async (doctorId) => {
        try {
            const result = await axios.get(`${API}/getinwarddata/${doctorId}`)
            return result.data
        }
        catch (err) {
            return err
        }
    }
    const deleteInwardDetails = async (doctorId, clinicId) => {
        try {
            const result = await axios.post(`${API}/deleteinwarddata/${doctorId}/${clinicId}`)
            return result.data
        }
        catch (err) {
            return err
        }
    }
    return {
        addInward,
        getInwardDetails,
        editInwardDetails,
        deleteInwardDetails
    }
}