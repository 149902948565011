import React, { useEffect } from "react"
import { useRecoilState } from "recoil";
import { setPatientLifestyle } from "../recoil/atom/setPatientLifestyle";
import PatientApi from "../services/PatientApi";

function FetchPatientLifestyleData(props) {
    const { patientId } = props;
    const [fetchPatientdata, setFetchPatientData] = useRecoilState(setPatientLifestyle)
    const { getPatientLifestyle } = PatientApi()

    useEffect(() => {
        getPatientData()
    }, [])

    function getPatientData() {
        getPatientLifestyle(patientId)
            .then((result) => {
                setFetchPatientData(result)
            })
    }
    return (
        <>
        {fetchPatientdata && fetchPatientdata.length > 0 ?
         <>
         {fetchPatientdata.map((item, index) => (
            <div className="width-32 patientBox mt-2 ml-1 mr-1">
                <div className="underline">
                    <div className="form_title">
                        <h4>Patient LifeStyle</h4>
                    </div>
                </div>
                <div className="patientDataStyle">
                    <div>
                        <label className="font_weight mx-2" >Smoking Habits :</label>
                        {item.smokingHabits}
                    </div>
                    <div>
                        <label className="font_weight mx-2" >Alcohol Consumption :</label>
                        {item.alcoholConsumption}
                    </div>
                    <div>
                        <label className="font_weight mx-2" >Food Preferences :</label>
                        {item.foodPreferences}
                    </div>
                    <div>
                        <label className="font_weight mx-2" >Occupation :</label>
                        {item.occupation}
                    </div>
                </div>
            </div>
        ))}
         </>:
         null
        }
        </>
    )
}
export { FetchPatientLifestyleData }