import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import { MainInput } from "../../../mainComponent/mainInput";
import AuthApi from "../../../services/AuthApi";
import { useRecoilState } from "recoil";
import { setDoctorId } from "../../../recoil/atom/setDoctorId";
import Toaster from "../../Toaster";

export default function ForgotDoctorMpin(props) {
    const { mobile } = props;
    const [isError, setIsError] = useState(false);
    const [doctorId, setdoctorId] = useRecoilState(setDoctorId);
    const { forgotMpin } = AuthApi();
    const navigate = useNavigate()
    const [data, setData] = useState(
        {
            password: '',
            confirmPassword: ''
        }
    );
    const handleChange = (event) => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const password = data.password.trim();
        const confirmPassword = data.confirmPassword.trim();
        if (password === '' || confirmPassword === '') {
            setIsError(true);
        }
        else if (password === confirmPassword) {
            const bodyData = {
                mobile: mobile,
                password: password,
            }
            forgotMpin(bodyData)
                .then(response => {
                    const doctorId = response.data._id
                    setdoctorId(response.data._id)
                    setIsError(response.data.message)
                    if (response.data.isSubscribed === true) {
                        navigate(`/profile/${doctorId}`)
                    } else {
                        navigate(`/subscriptions/${doctorId}`);
                    }
                })
        }
        else {
            setIsError(true)
        }

    };

    return (
        <div className="row">
            <lable className="mb-2 fontSize">Enter MPIN</lable>
            <div className="col-md-12">
                <MainInput
                    type="password"
                    name="password"
                    maxLength={6}
                    value={data.password}
                    pattern="[+-]?\d+(?:[.,]\d+)?"
                    onChange={handleChange}
                    placeholder="New MPIN"
                    required
                >
                </MainInput>
            </div>

            <lable className="mb-2 fontSize">Confirm MPIN</lable>
            <div className="col-md-12">
                <MainInput
                    type="password"
                    name="confirmPassword"
                    value={data.confirmPassword}
                    maxLength={6}
                    pattern="[+-]?\d+(?:[.,]\d+)?"
                    onChange={handleChange}
                    placeholder="Confirm MPIN"
                    required>
                </MainInput>
            </div>
            <div className="row float-right toaster">
                <Toaster />
            </div>
            <div className="">
                <MainButtonInput onClick={handleSubmit}>Reset Mpin</MainButtonInput>
            </div>
            {isError === true ? <div className="validation mb-2 ml-3">Password is mismatch</div> : null}
        </div>

    )
}