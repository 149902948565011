import React, { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { MainInput } from '../../../mainComponent/mainInput';
import LabWorkApi from '../../../services/LabWorkApi';
import { MainButtonInput } from '../../../mainComponent/mainButtonInput';
import { Box } from '@mui/material';
import AuthApi from '../../../services/AuthApi';
import AppointmentsApi from '../../../services/AppointmentsApi';
import { API } from '../../../config';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { setLabworkData } from '../../../recoil/atom/setLabWorkData';
import { Modal, Button } from "react-bootstrap";

export default function UpdateLabWorkTable(props) {
    const { doctorId, labWorkId } = props
    const [updateLabWorkData, setupdateLabWorData] = useRecoilState(setLabworkData);
    const [saveWork, setSaveWork] = useState("");
    const [saveLab, setSaveLab] = useState("");
    const [savepatient, setSavePatient] = useState("");
    const [saveClinic, setSaveClinic] = useState("");
    const [saveTooth, setSaveTooth] = useState([]);
    const [WokType, setWorkType] = useState([]);
    const [patient, setPatient] = useState([]);
    const [lab, setLab] = useState([]);
    const [file, setFile] = useState(null);
    const [clinic, setClinic] = useState([]);
    const [labWokData, setLabWorkData] = useState([]);
    const [givenDate, setGivenDate] = useState('');
    const [rcvDate, setRcvDate] = useState('');
    const [selectedTeeth, setSelectedTeeth] = useState([])
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { getWorkTypes, getlabsInfo, getLabWorkById, updateLabWork } = LabWorkApi()
    const { getDrInfo } = AuthApi()
    const { getAppointmentsDetails } = AppointmentsApi();

    useEffect(() => {
        getLabWorkData();
        generateOctalCombinations();
        getLabWorkData();
    }, [])

    const getLabWorkData = () => {
        getLabWorkById(labWorkId)
            .then((res) => {
                setLabWorkData(res)
                setSaveTooth(res.toothnumber)
                setSaveWork(res)
                setSaveLab(res)
                setSavePatient(res.patient)
                setSaveClinic(res.clinic)
                setUploadedFiles(res["documents"])
                // setGivenDate(res.givendate)
                // setRcvDate(res.recievedate)
            })

        getWorkTypes()
            .then((result) => {
                setWorkType(result)
            })

        getlabsInfo()
            .then((result) => {
                setLab(result)
            })

        getDrInfo({ doctorId })
            .then((result) => {
                if (result.result[0]["clinicList"]) {
                    const clinics = result.result[0]["clinicList"]
                    const clinicNames = clinics.map(item => item.clinicName);
                    setClinic(clinicNames)
                } else {
                    setClinic('')
                }
            })

        getAppointmentsDetails(doctorId)
            .then((result) => {
                if (result.test) {
                    const res = result.test.filter(function (item, index) {
                        return index === result.test.findIndex((obj) => {
                            if (item.patientId === obj.patientId)
                                return item
                        })
                    })
                    const patientNames = res.map(item => item.patientDetails[0].name);
                    setPatient(patientNames)
                } else {
                    return null
                }
            })
    };

    const addGivenDatePicker = (date) => {
        setGivenDate(date)
    }

    const handleFileChange = (event) => {
        setFile(event.target.files);
    };

    const addRcvDatePicker = (date) => {
        setRcvDate(date)
    }

    const handleWorkType = (event, selectedValue) => {
        event.preventDefault()
        setSaveWork(selectedValue)
    }

    const handleLab = (event, selectedValue) => {
        event.preventDefault()
        setSaveLab(selectedValue)
    }

    const handlePatients = (event, selectedValue) => {
        event.preventDefault()
        setSavePatient(selectedValue)
    }

    const handleClinics = (event, selectedValue) => {
        event.preventDefault()
        setSaveClinic(selectedValue)
    }

    const handleTooths = (event, selectedValue) => {
        event.preventDefault()
        setSaveTooth(selectedValue)
    }

    //for all input onchange method
    const handleInputChange = event => {
        const { name, value } = event.target;
        setLabWorkData({ ...labWokData, [name]: value });
    };


    const openModal = (url) => {
        setSelectedImage(url);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedImage(null);
    };

    function generateOctalCombinations() {
        let combinations = [];

        for (let x = 1; x <= 4; x++) {
            for (let y = 1; y <= 8; y++) {
                // Convert x and y to octal format
                let octalX = x.toString(16)
                let octalY = y.toString(16)
                combinations.push({
                    value: `${octalX} , ${octalY}`,
                });
            }
        }
        setSelectedTeeth(combinations)
    }

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        const bodyData = {
            'doctorId': doctorId,
            'workname': labWokData.workname,
            'worktype': saveWork.worktype,
            'price': saveWork.price,
            'labname': saveLab.labname,
            'labcontact': saveLab.labcontact,
            'patient': savepatient,
            'clinic': saveClinic,
            'toothnumber': saveTooth,
            // 'givendate': givenDate,
            // 'recievedate': rcvDate,
            'comments': labWokData.comment,
        }
        updateLabWork(labWorkId, bodyData)
            .then(async(res) => {
                const update = updateLabWorkData.map(function (e, index) {
                    if (labWorkId === e._id) {
                        return res
                    } else {
                        return e
                    }
                })
                setupdateLabWorData(update);
                const formData = new FormData();
                if(file){
                    Array.from(file).forEach((singleFile) => formData.append("photo", singleFile));
                    try {
                        await axios.post(`${API}/labreports/${labWorkId}`, formData, {
                            headers: { "Content-Type": "multipart/form-data" }
                        });
                        alert("file uploaded successfully")
                    } catch (error) {
                        console.error("Error uploading files", error.response ? error.response.data : error.message);
                    }
                }
            })
        props.onSubmit()
    }

    return (
        <form onSubmit={onSubmitHandler}>
            <div className="row">
                <div className="col-md-6 ">
                    <div className='mt-2'>
                        <label className="font_weight">Work Name</label>
                        <MainInput
                            type="text"
                            value={labWokData.workname}
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder='Work Name'
                            name="workname"
                        />
                    </div>

                    <div>
                        <label className="font_weight">Work type</label>
                        <Autocomplete
                            disablePortal={true}
                            disableClearable
                            disableCloseOnSelect
                            value={saveWork.worktype}
                            onChange={handleWorkType}
                            getOptionLabel={(data) => `${data.worktype}`}
                            options={WokType}
                            renderOption={(props, option) => (<Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>{option.worktype}</Box>)}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Work type"
                                />}
                        />
                    </div>

                    <div className='mt-2'>
                        <label className="font_weight">Price</label>
                        <MainInput
                            type="text"
                            value={saveWork.price}
                            className="form-control"
                            placeholder='Price'
                            name="price"
                        />
                    </div>

                    <div className='mt-2'>
                        <label className="font_weight">Patient List</label>
                        <Autocomplete
                            disablePortal={true}
                            disableClearable
                            disableCloseOnSelect
                            value={savepatient}
                            onChange={handlePatients}
                            getOptionLabel={(data) => `${data}`}
                            options={patient}
                            renderOption={(props, option) => (<Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>{option}</Box>)}
                            noOptionsText={"patients not available"}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Patient Name"
                                />
                            }
                        />
                    </div>

                    <div className='mt-2'>
                        <label className="font_weight">Given Date</label>
                        <div className="form-group">
                            <DatePicker
                                disabled
                                className="datepicker"
                                onChange={addGivenDatePicker}
                                value={givenDate}
                                clearIcon={null}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className='mt-2'>
                        <label className="font_weight">Tooth Number</label>
                        <Autocomplete
                            multiple={true}
                            disablePortal={true}
                            disableClearable
                            disableCloseOnSelect
                            value={saveTooth}
                            onChange={handleTooths}
                            getOptionLabel={(data) => `${data}`}
                            options={selectedTeeth.map((n) => `${n.value}`)}
                            renderOption={(props, option) => (<Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>{option}</Box>)}
                            noOptionsText={"Empty tooth"}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Select Tooth"
                                />
                            }
                        />
                    </div>

                    <div className='mt-3'>
                        <label className="font_weight">Lab Name </label>
                        <Autocomplete
                            id={lab._id}
                            autoHighlight
                            disablePortal={true}
                            disableClearable
                            disableCloseOnSelect
                            value={saveLab.labname}
                            onChange={handleLab}
                            getOptionLabel={(data) => `${data.labname}`}
                            options={lab}
                            noOptionsText={"Lab not available"}
                            renderOption={(props, option) => (<Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>{option.labname}</Box>)}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Lab Name"
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                />}
                        />
                    </div>

                    <div className='mt-2'>
                        <label className="font_weight">Contact</label>
                        <MainInput
                            type="text"
                            value={saveLab.labcontact}
                            className="form-control"
                            placeholder='Contact'
                            name="labcontact"
                        />
                    </div>

                    <div className='mt-2'>
                        <label className="font_weight">Clinic List</label>
                        <Autocomplete
                            disablePortal={true}
                            disableClearable
                            disableCloseOnSelect
                            value={saveClinic}
                            onChange={handleClinics}
                            getOptionLabel={(data) => `${data}`}
                            renderOption={(props, option) => (<Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>{option}</Box>)}
                            options={clinic}
                            noOptionsText={"Clinic not available"}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Clinic Name"
                                />
                            }
                        />
                    </div>

                    <div className='mt-2'>
                        <label className="font_weight">Received Date</label>
                        <div className="form-group">
                            {rcvDate && rcvDate ?
                                <DatePicker
                                    disabled={true}
                                    className="datepicker"
                                    onChange={addRcvDatePicker}
                                    value={rcvDate}
                                    clearIcon={null}
                                />
                                :
                                <DatePicker
                                    disabled={false}
                                    className="datepicker"
                                    onChange={addRcvDatePicker}
                                    value={rcvDate}
                                    clearIcon={null}
                                />
                            }

                        </div>
                    </div>
                </div>
            </div>

            <div className='mt-2'>
                <label className="font_weight">Comment</label>
                <textarea
                    // style={{ width: 200 }}
                    type="text"
                    value={labWokData.comments}
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder='Optional'
                    name="comments" />
            </div>

            <div className='mt-2 mb-2'>
                <label className="font_weight">Upload Your Documents</label>
                {uploadedFiles && 
                    uploadedFiles.length >= 2 ? (
                        <>
                            <div className="form-group">
                                <input
                                    type="file"
                                    className="disabled-div"
                                    disabled={true}
                                />
                            </div>
                            <span>You're hitting a limit for uploading files.</span>
                        </>
                    ) : (
                        <>
                            <MainInput
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                onChange={handleFileChange}
                                name="photo"
                                multiple
                            />
                            <span>You can only upload a maximum of 2 files.</span>
                        </>
                    )
                }
            </div>
            {uploadedFiles && uploadedFiles.length > 0 && (
                <div>
                    <h6 align="left" className='mb-2'>Uploaded Files:</h6>
                    <div className='row'>
                        {uploadedFiles.map((url, index) => (
                            <div key={index}>
                                <img 
                                    src={url} 
                                    alt={`Uploaded file ${index + 1}`} 
                                    className='uploadedimg' 
                                    onClick={() => openModal(url)}
                                    />
                            </div>
                        ))}
                    </div>
                </div>
            )}

            <div className="text-center mt-2 add_top_30">
                <MainButtonInput>Save</MainButtonInput>
            </div>

            {isModalOpen && (
                <Modal show={isModalOpen} onHide={closeModal}>
                    <Modal.Header closeButton onClick={(e) => e.stopPropagation()}>
                    </Modal.Header>
                    <Modal.Body>
                        <img src={selectedImage} alt="Selected Image" className="modal-image" />
                    </Modal.Body>
                </Modal>
            )}
        </form>
    )
} 