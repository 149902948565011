import { useEffect, useState } from "react";
import { Link, useNavigate, Outlet } from "react-router-dom";
import PatientApi from "../services/PatientApi";
import { useRecoilState } from "recoil";
import { setFetchPatientData } from "../recoil/atom/setFetchPatientData";
import { setNewPatientId } from "../recoil/atom/setNewPatientId";

function FetchPatientInfo(props) {
    const { patientId } = props;
    const [patientsId, setPatientsId] = useRecoilState(setNewPatientId)
    const [ fetchPatientData, setFetchPatientdata] = useRecoilState(setFetchPatientData)
    const { patientDetailsData} = PatientApi()
    const navigate = useNavigate()

    useEffect(() => {
        getAllPatientData()
    }, []);

    function getAllPatientData() {
        patientDetailsData({patientId})
            .then( (response) =>{
                setFetchPatientdata(response[0])
            })
    }

    const handleClick = async (event) => {
        event.preventDefault()
        navigate(`booking/${patientId}`)
        setPatientsId(patientId)
    }
    return (
        <>
        {fetchPatientData ? 
        <>
            <div className="underline mt-2">
                <div className="form_title">
                    <h3>Patient Details</h3>
                </div>
            </div>
            <div className="patientDataStyle">
                <div >
                    <label className="font_weight mx-2" >Patient name :</label>
                    {fetchPatientData.name}
                </div>
                <div >
                    <label className="font_weight mx-2" >Age :</label>
                    {fetchPatientData.age}
                </div>
                <div >
                    <label className="font_weight mx-2" >Gender :</label>
                    {fetchPatientData.gender}
                </div>
                <div >
                    <label className="font_weight mx-2" >Email :</label>
                    {fetchPatientData.email}
                </div>
                <div align='right'>
                    <div className="radius appColor buttonPatient" align='center'>
                        <Link onClick={(event)=>handleClick(event)} className="btn">
                            <span className="appColor">Book Appointment</span>
                        </Link>
                    </div>
                </div>
            </div>
            <Outlet/>
        </>:null
        }
        </>
    )
}
export { FetchPatientInfo }