import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Wrapper } from '../../mainComponent/Wrapper';
import UserLinks from '../Dashboard-card/partial/uselinks';
import { MainNav } from '../../mainComponent/mainNav';
import { useRecoilState } from 'recoil';
import { setHelperData } from '../../recoil/atom/setHelperData';
import { setDoctorId } from '../../recoil/atom/setDoctorId';
import PrescriptionSummary from './partial/PrescriptionSummary';

export default function ViewMedicalReport() {
    const { reportId } = useParams();
    const [helpersData, setHelpersData] = useRecoilState(setHelperData)
    const [doctorId, setDoctorsId] = useRecoilState(setDoctorId)

    return (
        <Wrapper>
            <MainNav>
                <ul className="clearfix">
                    <li>
                        <Link to={`/history/${doctorId}`}>
                            <i className="arrow_back backArrow" title="back button"></i>
                        </Link>
                    </li>
                    <li className='float-none' style={{ fontSize: 'inherit' }}>Prescription</li>
                </ul>
            </MainNav>
            <div className="row">
                <div className='width_16'>
                    <div className='dash row'>
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module}
                        />
                    </div>
                </div>
                <div className='width_84'>
                    <div className="common_box ">
                        <PrescriptionSummary reportId={reportId} />
                    </div>
                </div>
            </div>
        </Wrapper >
    )
}