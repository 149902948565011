import axios from "axios";
import { API } from "../config";

export default function AppointmentsApi() {

    const getAppointmentsDetails = async (doctorId, data) => {
        try {
            const result = await axios.post(`${API}/getBookingData/${doctorId}`, data);
            return result.data;
        }
        catch (err) {
            return err
        }

    }
    const updateIncompleteStatus = async (patientAppointmentId, bodyData) => {
        try {
            const result = await axios.post(`${API}/updateIncompleteStatus/${patientAppointmentId}`, bodyData)
            return result.data
        }
        catch (err) {
            return err
        }
    }
    const UpdateStatusBookingdata = async ({ appointmentId }, bodyData) => {
        try {
            const result = await axios.post(`${API}/updateStatus/${appointmentId}`, bodyData)
            return result.data
        }
        catch (err) {
            return err
        }

    }
    const createPDF = async (reportId) => {
        try {
            const result = await axios.post(`${API}/createprescriptionpdf/${reportId}`)
            return result.data
        }
        catch (err) {
            return err
        }
    };
    const downloadPrescription = async (reportId) => {
        try {
            const result = await axios.get(`${API}/download-prescription/${reportId}`);
            return result.data;
        }
        catch (err) {
            return err
        }

    }
    const cancelPatientAppointment = async (id) => {
        try {
            const result = await axios.delete(`${API}/cancelappointment/${id}`)
            return result
        }
        catch (err) {
            return err
        }

    }

    const getPaymentData = async (id) => {
        try {
            const result = await axios.get(`${API}/getappointment/${id}`)
            return result.data;
        }
        catch (err) {
            return err
        }
    };

    const getAppointment = async (appointmentId) => {
        try {
            const result = await axios.get(`${API}/getappointmentdata/${appointmentId}`)
            return result.data;
        }
        catch (err) {
            return err
        }
    };

    return {
        getAppointmentsDetails,
        UpdateStatusBookingdata,
        createPDF,
        downloadPrescription,
        cancelPatientAppointment,
        updateIncompleteStatus,
        getPaymentData,
        getAppointment
    }
}

