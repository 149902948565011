import React from 'react';
import { EditEducation } from "./EditEducation";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { setDoctorEducation } from "../../../../recoil/atom/setDoctorEducation";
import EducationalApi from '../../../../services/EducationalApi';
import Loader from '../../../Dashboard-card/Loader';
import { Drawer, IconButton, Typography, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function FetchEducation(props) {
    const { doctorId } = props
    const [eduData, setEduData] = useRecoilState(setDoctorEducation);
    const [activeModal, setActiveModal] = useState(false);
    const [Item, setItem] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const { fetchAllEducations, deleteEducationData } = EducationalApi();
    const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [education, setEducation] = useState([]);
    const isMobile = useMediaQuery('(max-width:768px)')
    useEffect(() => {
        getAllEducations()
    }, [])

    const handleDeleteShow = (item) => {
        setItem(item)
        setShowDelete(true)
    }
    const handleDeleteClose = () => setShowDelete(false)

    const handleClose = () => {
        setActiveModal(false)
        setOpen(!open)
    }
    const handleShow = (e, education) => {
        setEducation(education)
        setActiveModal(true)
        setOpen(!open)
    };

    const getAllEducations = () => {
        setIsLoading(true);
        fetchAllEducations({ doctorId })
            .then((res) => {
                setEduData(res.data);
                setIsLoading(false);
            })
    }
    const deleteEducation = (education) => {
        const id = education._id
        deleteEducationData(id)
            .then(() => {
                getAllEducations()
            })
        handleDeleteClose()
    }

    return (
        <>
            {isLoading ?
                <div className='loader-container'>
                    <Loader />
                </div>
                :
                <>
                    {eduData && eduData.length > 0 ?
                        <div className='row'>
                            {eduData && eduData.map((education, index) => {
                                return (
                                    <div className='col-md-5 ' key={index}>

                                        <div className='grayBox'>
                                            <div className='row'>
                                                <div className='col-md-9' align='left'>
                                                    <div>
                                                        <span className='font_weight'>
                                                            Doctor Degree
                                                        </span>
                                                        <div>{education.degree}</div>
                                                    </div>
                                                    <div>
                                                        <span className='font_weight'>
                                                            Doctor Collage/University
                                                        </span>
                                                        <div>{education.collage}</div>
                                                    </div>
                                                    <div>
                                                        <span className='font_weight'>
                                                            Specialization
                                                        </span>
                                                        <div>{education.specialization}</div>
                                                    </div>
                                                    <div>
                                                        <span className='font_weight'>
                                                            Complition Year -
                                                        </span>
                                                        <span>{education.comYear}</span>
                                                    </div>
                                                </div>

                                                <div className='col-md-3' align='right'>
                                                    <Link
                                                        onClick={e => handleShow(e, education)}
                                                        to="#"
                                                        align='right'
                                                        className="editbutton">
                                                        <i className="icon_pencil-edit"
                                                            title="Edit profile">
                                                        </i>
                                                    </Link>
                                                    <Link
                                                        onClick={() => handleDeleteShow(education)}
                                                        to="#"
                                                        align='right'
                                                        className="editbutton">
                                                        <i className="icon-trash-2"
                                                            title="delete profile">
                                                        </i>
                                                    </Link>
                                                </div>
                                                {/* <FetchImages imageData={education.document} /> */}
                                            </div>
                                        </div>
                                    </div>

                                )
                            })

                            }
                        </div>
                        : <div className='clinicHistory font_weight'>Add your education</div>}
                    <>
                        {isMobile ? (
                            <Drawer anchor="bottom" open={open} onClose={handleClose}>
                                <div className='drawerTitle underline' >
                                    <Typography variant="h6">Edit Education Data</Typography>
                                    <IconButton onClick={handleClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                                <div className='p-4'>
                                    <EditEducation
                                        imageData={education.document}
                                        doctorId={doctorId}
                                        EduId={education._id}
                                        onSubmit={handleClose}
                                    />
                                </div>
                            </Drawer>
                        ) : (
                            <Modal show={activeModal}
                                onHide={handleClose}
                                id={`education-${education._id}`}
                                key={education._id}>
                                <Modal.Header closeButton >
                                    <Modal.Title>Edit Education Data</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <EditEducation
                                        imageData={education.document}
                                        doctorId={doctorId}
                                        EduId={education._id}
                                        onSubmit={handleClose}
                                    />
                                </Modal.Body>
                            </Modal>
                        )
                        }
                    </>
                </>
            }
            <div>
                <Modal show={showDelete} onHide={handleDeleteClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Are you sure?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="alert " style={{ backgroundColor: '#e1e8ed', fontSize: 20 }}>You want to delete this education details.</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" className='appColor' onClick={() => deleteEducation(Item)}>
                            Yes
                        </Button>
                        <Button variant="default" className='borderStyle' onClick={handleDeleteClose}>
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}
export { FetchEducation }