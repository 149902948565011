import React, { useEffect, useState } from 'react';
import "react-toastify/dist/ReactToastify.css";
import Toaster from '../../Toaster';
import { toast } from 'react-toastify';
import { MainButtonInput } from '../../../mainComponent/mainButtonInput';
import DatePicker from 'react-date-picker';
import { Autocomplete, Box, TextField } from '@mui/material';
import AuthApi from '../../../services/AuthApi';
import InventoryApi from '../../../services/InventoryApi';
import { useRecoilState } from 'recoil';
import { setClinicId } from '../../../recoil/atom/setClinicId';

export default function InventoryModalBox(props) {
    const { doctorId } = props;
    const { addInward } = InventoryApi()
    const { getDrInfo } = AuthApi()
    const [addInwardData, setInwardData] = useState({})
    const [expiryDate, setExpiryDate] = useState();
    const [inwardDate, setInwardDate] = useState();
    const [clinicId, setClinicid] = useRecoilState(setClinicId);
    const [clinicList, setClinicList] = useState([]);
    const [saveClinic, setSaveClinic] = useState([]);

    useEffect(() => {
        getDrDetails();
    }, [])

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInwardData({ ...addInwardData, [name]: value })
    }

    const getDrDetails = () => {
        getDrInfo({ doctorId })
            .then((res) => {
                const clinicList = res.result[0]['clinicList']
                if (clinicList) {
                    setClinicList(clinicList)
                } else {
                    setClinicList('')
                }
            })
    }
    const handleClinics = (event, selectedValue) => {
        event.preventDefault()
        setSaveClinic(selectedValue)
    }

    const saveData = (e) => {
        e.preventDefault();
        const bodyData = {
            doctorId: doctorId,
            clinicId: saveClinic._id,
            itemName: addInwardData.itemName,
            manufacturerName: addInwardData.manufacturerName,
            category: addInwardData.category,
            subCategory: addInwardData.subCategory,
            expiryDate: expiryDate,
            inwardDate: inwardDate,
            quantity: addInwardData.quantity,
            cost: addInwardData.cost,
            spoc: addInwardData.spoc,
            clinicName: saveClinic.clinicName
        }
        addInward(doctorId, saveClinic._id, bodyData)
        toast.success("Saved Successfully!")
        setClinicid(saveClinic._id)
        props.onSubmit()
    }

    const addExpiryDate = (date) => {
        setExpiryDate(date)
    }
    const addInwardDate = (date) => {
        setInwardDate(date)
    }

    return (
        <div>
            <div className="">
                <div className="row  mt-2">
                    <div className="col-md-6">
                        <label className='left font_weight' >Item Name</label>
                        <input
                            type="text"
                            className="form-control"
                            value={addInwardData.itemName}
                            name="itemName"
                            placeholder="Item Name"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6 ">
                        <label className='left font_weight'>Manufacturer Name</label>
                        <input
                            type="text"
                            className="form-control"
                            value={addInwardData.manufacturerName}
                            name="manufacturerName"
                            placeholder="Manufacturer Name"
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-6">
                        <label className='left font_weight'>Category</label>
                        <input
                            type="text"
                            className="form-control"
                            value={addInwardData.category}
                            name="category"
                            placeholder="Category"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6">
                        <label className='left font_weight'>Subcategory</label>
                        <input
                            type="text"
                            className="form-control"
                            value={addInwardData.subCategory}
                            name="subCategory"
                            placeholder="Subcategory"
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="row  mt-2">
                    <div className="col-md-6">
                        <label className='left font_weight' >Quantity</label>
                        <input
                            type="text"
                            className="form-control"
                            value={addInwardData.quantity}
                            name="quantity"
                            placeholder="Quantity"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6">
                        <label className='left font_weight' >Cost</label>
                        <input
                            type="text"
                            value={addInwardData.cost}
                            className="form-control"
                            name="cost"
                            placeholder="Cost"
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="row  mt-2">

                    <div className="col-md-6">
                        <label className='left font_weight'>SPOC</label>
                        <input
                            type="text"
                            value={addInwardData.spoc}
                            className="form-control"
                            name="spoc"
                            placeholder="Spoc"
                            onChange={handleChange}
                        />
                    </div>

                    <div className="col-md-6">
                        <label className="font_weight">Clinic List</label>
                        <Autocomplete
                            disablePortal={true}
                            disableClearable
                            disableCloseOnSelect
                            value={saveClinic.clinicName}
                            onChange={handleClinics}
                            getOptionLabel={(data) => `${data.clinicName}`}
                            renderOption={(props, option) => (<Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>{option.clinicName}</Box>)}
                            options={clinicList}
                            noOptionsText={"Clinic not available"}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Clinic Name"
                                />
                            }
                        />
                    </div>
                </div>
                <div className="row  mt-2">
                    <div className="col-md-6">
                        <div className='mt-2'>
                            <label className="font_weight">Inward Datee</label>
                            <div className="form-group">
                                <DatePicker
                                    className="datepicker"
                                    onChange={addInwardDate}
                                    value={inwardDate}
                                    clearIcon={null}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className='mt-2'>
                            <label className="font_weight">Expiry Date</label>
                            <div className="form-group">
                                <DatePicker
                                    className="datepicker"
                                    onChange={addExpiryDate}
                                    value={expiryDate}
                                    clearIcon={null}
                                />
                            </div>
                        </div>
                    </div>

                </div>
                <div className="row float-right">
                    <Toaster />
                </div>
            </div>
            <div className=" float-right mt-3 mr-3">
                <MainButtonInput onClick={saveData}> Save</MainButtonInput>
            </div>
        </div >
    )

}
