import React, { useEffect, useState } from "react";
import { setHelperData } from "../../../recoil/atom/setHelperData";
import { Wrapper } from '../../../mainComponent/Wrapper';
import { useRecoilState } from "recoil";
import { Link, useParams } from 'react-router-dom';
import { MainNav } from '../../../mainComponent/mainNav';
import UserLinks from '../partial/uselinks';
import { MainSelect } from "../../../mainComponent/mainSelect";
import MonthlyReport from "./monthlyreport";
import YearlyReport from "./yearlyreport";

export default function Reports() {
    const { doctorId } = useParams();
    const [helpersData] = useRecoilState(setHelperData)
    const [months, setMonths] = useState([]);
    const [years, setYears] = useState([]);
    const [payments, setPayments] = useState([]);

    useEffect(() => {
        getYearsAndMonths()
    }, [payments]);

    const getYearsAndMonths = (monthName) => {
        const currentYear = new Date().getFullYear();
        const getYears = [];
        const prevYear = currentYear - 10;
        let x = prevYear;
        while (x <= currentYear) {
            getYears.push(x);
            x++;
        }
        setYears(getYears)
        const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        setMonths(month)
        if (typeof monthName !== 'string' || monthName.trim() === '') {
            return null;
        }
        const monthNameFormatted = monthName.charAt(0).toUpperCase() + monthName.slice(1).toLowerCase();
        const monthIndex = month.indexOf(monthNameFormatted);
        return monthIndex !== -1 ? monthIndex + 1 : null;
    }

    //for all input onchange method
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setPayments({ ...payments, [name]: value });
    };

    return (
        <Wrapper>
            <MainNav>
                <div className="clearfix row">
                    <div className="width50">
                        <ul className="clearfix">
                            <li className='float-none' style={{ fontSize: 'inherit' }}>Report</li>
                        </ul>
                    </div>
                </div>
            </MainNav>

            <div className='row'>
                <div className='width_16'>
                    <div className='dash row'>
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module}
                        />
                    </div>
                </div>
                <div className='width_84'>
                    <div className="white-box">
                        <div className='row' align='left'>
                            <div className='mr-4'>
                                <label className='mb-2 font_weight' value="lab">
                                    Select Year
                                </label>
                                <div className='mt-2'>
                                    <MainSelect
                                        name="year"
                                        value={payments.year}
                                        onChange={handleInputChange}>
                                        <option value="">Select Year</option>
                                        {years.map((item, index) => (
                                            <option key={index} value={item} className="form-control">{item}</option>
                                        ))}
                                    </MainSelect>
                                </div>
                            </div>
                            <div className='mr-4'>
                                <label className='mb-2 font_weight' value="lab">Select Month</label>
                                <div className='mt-2'>
                                    <MainSelect
                                        name="month"
                                        value={payments.month}
                                        onChange={handleInputChange}>
                                        <option value="">Select Month</option>
                                        {months.map((item, index) => (
                                            <option key={index} value={item} className="form-control">{item}</option>
                                        ))}
                                    </MainSelect>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="white-box">
                        <MonthlyReport payments={payments} doctorId={doctorId} />
                    </div>
                    <div className="white-box">
                        <YearlyReport payments={payments} doctorId={doctorId} />
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}