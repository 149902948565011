import React, { useState } from 'react';
import { TabPanel } from "../../common/tabpanel";
import { DoctorProfessionalExperience } from "./Experience/doctorProfessionalExperience"
import { DoctorEducation } from "./Education/doctorEducation";
import { DoctorPersonalInformation } from "./Personal/DoctorPersonalInformation";
import { MainNav } from '../../mainComponent/mainNav';
import { MainTabs } from '../../mainComponent/mainTabs';
import { Link, useParams } from 'react-router-dom';
import { Wrapper } from '../../mainComponent/Wrapper';
import UserLinks from '../Dashboard-card/partial/uselinks';
import { setHelperData } from "../../recoil/atom/setHelperData";
import { useRecoilState } from "recoil";
import { AddDoctorClinicInfo } from './Clinic/Partial/AddDoctorClinicInfo';
import { AddTreatment } from './Treatment/AddTreatment';

export default function EditDoctorProfile() {
    const { doctorId } = useParams();
    const [helpersData, setHelpersData] = useRecoilState(setHelperData)
    //for using tab
    const [tabValue, setTabValue] = useState(0);
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const goToEducation = () => {
        setTabValue(1)
    }
    const goToExperience = () => {
        setTabValue(2)
    }
    const goToClinic = () => {
        setTabValue(3)
    }

    const goToTreatement = () => {
        setTabValue(4)
    }

    return (
        <Wrapper>
            <MainNav>
                <ul className="clearfix">
                    <li>
                        <Link to={`/profile/${doctorId}`}>
                            <i className="arrow_back backArrow" title="back button"></i>
                        </Link>
                    </li>
                    <li>
                        <span to="#section_1" className="active ">
                            Doctor Information
                        </span>
                    </li>
                    <li className='mt-2' >
                        <Link to={`/profile/${doctorId}`}>
                            Done
                        </Link>
                    </li>
                </ul>
            </MainNav>
            <div className='row'>
                <div className='width_16'>
                    <div className='dash row'>
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module}
                        />
                    </div>
                </div>
                <div className='width_84'>
                    <div className="common_box">
                        <div className="white-box pb-5">
                            <MainTabs
                                value={tabValue}
                                onChange={handleChange}
                                label="Personal Information"
                                label1="Educational Details"
                                label2="Professional Experience"
                                label3="Clinic"
                                label4="Treatment"
                            >
                            </MainTabs>

                            <TabPanel value={tabValue} index={0}>
                                <DoctorPersonalInformation
                                    data={goToEducation}
                                    doctorId={doctorId}
                                />
                            </TabPanel>

                            <TabPanel value={tabValue} index={1}>
                                <DoctorEducation
                                    data={goToExperience}
                                    doctorId={doctorId}
                                />
                            </TabPanel>

                            <TabPanel value={tabValue} index={2}>
                                <DoctorProfessionalExperience
                                    data={goToClinic}
                                    doctorId={doctorId}
                                />
                            </TabPanel>

                            <TabPanel value={tabValue} index={3}>
                                <AddDoctorClinicInfo
                                    data={goToTreatement}
                                    doctorId={doctorId} />
                            </TabPanel>

                            <TabPanel value={tabValue} index={4}>
                                <AddTreatment
                                    doctorId={doctorId} />
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}