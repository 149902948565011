import React from 'react';
import { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ReportApi from '../../../services/ReportApi';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Toaster, { showToast } from '../../Toaster';
import GetDentalServices from './getdentalservices';
import { Box } from '@mui/material';
import ServicesApi from '../../../services/servicesAPI';
import { useRecoilState } from 'recoil';
import { setFetchTreatment } from '../../../recoil/atom/setFetchTreatment';
import { MainButtonInput } from '../../../mainComponent/mainButtonInput';
import { SecondaryButtonInput } from '../../../mainComponent/secondaryButtonInput';
import Premedication from './Premedication';

export default function DentalServicesPriscription(props) {
    const { onChange, reportId, doctorId } = props
    const { insertDentalServices } = ReportApi()
    const [services, setGetServices] = useState([]);
    const [saveServices, setSaveservices] = useState([])
    const [fetchTreatment, setFetchtreatment] = useRecoilState(setFetchTreatment)
    const { getServices } = ServicesApi()

    useEffect(() => {
        getservicesData();
    }, [])

    const getservicesData = () => {
        getServices(doctorId)
            .then((result) => {
                setGetServices(result.data)
            })
    };

    const handleDataSave = (e, selectedData) => {
        e.preventDefault()
        setSaveservices(selectedData)
    }
    const DataSave = () => {
        const bodyData = {
            "reportId": reportId,
            "service_name": saveServices.name,
            "fees": saveServices.fees
        }
        insertDentalServices(bodyData)
            .then((response) => {
                setFetchtreatment(fetchTreatment.concat(response))
                showToast("Saved Successfully!")
            })
    }
    //git format-patch -1 48f43bf5489d8e76c198ea86095fa217e5688841

    return (
        <>
            <div className='row' >
                <div className='left col-md-4'>
                    <label className='left'>Treatments</label>
                    <Autocomplete
                        // id={WokType._id}
                        disablePortal={true}
                        disableClearable
                        disableCloseOnSelect
                        value={saveServices.name}
                        onChange={handleDataSave}
                        getOptionLabel={(data) => `${data.name}`}
                        options={services}
                        renderOption={(props, option) => (<Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>{option.name}</Box>)}
                        renderInput={(params) =>
                            <TextField {...params}
                                label="Choose service"
                            />}
                    />
                </div>

                <div className='col-md-4'>
                    <GetDentalServices reportId={reportId} />
                </div>
            </div>
            <div className="float-right row">
                <div className="text-left mt-2">
                    <MainButtonInput onClick={DataSave}>Save Treatments</MainButtonInput>
                </div>
                <div className="text-left mt-2 ml-2">
                    <SecondaryButtonInput onClick={onChange}>Next</SecondaryButtonInput>
                </div>
            </div>
            <div className="row float-right">
                <Toaster />
            </div>
        </>
    )
}