import React, { useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import LoginDoctor from "./doctor/Profile/LoginDoctor";
import DoctorProfile from "./doctor/Profile/DoctorProfile";
import EditDoctorProfile from "./doctor/Profile/EditDoctorProfile";
import Dashboard from './doctor/Dashboard-card/Dashboard';
import PatientMedicalReport from "./doctor/Report/PatientMedicalReport";
import PatientsClinicHistory from "./doctor/Dashboard-card/PatientsClinicHistory";
import CreatePatientProfile from "./patient/createPatientProfile";
import GetLoginPatientProfile from "./patient/getLoginPatientProfile";
import ViewMedicalReport from './doctor/Report/ViewMedicalReport';
import Logout from "./doctor/Profile/LogoutForm";
import Subscription from "./doctor/Profile/Subscription";
import LoginHelper from "./doctor/Profile/LoginHelper";
import SubscriptionCard from "./doctor/Dashboard-card/SubscriptionCard";
import Helper from "./doctor/helper/Helper";
import EditHelper from './doctor/helper/EditHelper';
import Patient from "./patient/patient";
import { AppointmentBookingSection } from "./patient/appointmentBookingSection";
import PatientData from "./doctor/Dashboard-card/partial/patientData";
import MedicineList from "./doctor/Dashboard-card/MedicineList";
import SubscriptionConfirmation from "./doctor/Dashboard-card/subscriptionConfirmation";
import { setDoctorId } from "./recoil/atom/setDoctorId";
import { useRecoilState } from "recoil";
import AddLabWork from './doctor/Dashboard-card/LabWork';
import PaymentList from './doctor/Dashboard-card/report/paymentlist';
import LabWorkList from './doctor/Dashboard-card/report/labworklist';
import Summary from './doctor/Report/partial/summary';
import MpinForm from './doctor/Profile/mpinform';
import CreateForm from './doctor/Profile/Partial/creatempin';
import CreatePatientMpin from './doctor/Profile/Partial/createPatientMpin';
import ForgetPatientMpin from './patient/forgetPatientMpin';
import ForgotDrMpin from './doctor/Profile/Partial/ForgotDrMpin';
import AddHelper from './doctor/helper/partial/AddHelper';
import Reports from './doctor/Dashboard-card/report/reportlist';
import CreateDoctorMpin from './doctor/Profile/Partial/CreateDoctorMpin';
import PatientLoginForm from './patient/patientLoginForm';
import { PatientMpin } from './patient/PatientMpin';
import Inwards from './doctor/Dashboard-card/inventory/Inwards';
import Outwards from './doctor/Dashboard-card/inventory/Outwards';

function MainContainer() {
  const [doctorId] = useRecoilState(setDoctorId);

  useEffect(() => {
    // Function to clear localStorage
    const clearLocalStorage = () => {
      localStorage.removeItem('setDoctorId'); // Replace 'yourRecoilDataKey' with your actual key
    };
    // Add event listener for beforeunload
    window.addEventListener('beforeunload', clearLocalStorage);
    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener('beforeunload', clearLocalStorage);
    };
  }, [])

  return (
    <Routes>
      <Route exact path="/" element={<MpinForm />} />
      <Route exact path="/create" element={<CreateDoctorMpin />} />
      <Route exact path="/forgetmpin" element={<ForgotDrMpin />} />
      <Route path="/login" element={<LoginDoctor />} />
      {/* <Route exact path="/" >
        <Route index element={doctorId ? <Navigate to='/dashboard/:doctorId' /> : <LoginDoctor />} />
      </Route> */}
      <Route path="/subscriptions/:doctorId" element={doctorId ? <Subscription /> : <Navigate to='/' />} />
      <Route path="/dashboard/:doctorId" element={doctorId ? <Dashboard /> : <Navigate to='/' />} />

      {/* doctor profile */}
      <Route path="/profile/:doctorId">
        <Route index element={doctorId ? <DoctorProfile /> : <Navigate to='/' />} />
        <Route path="edit" element={doctorId ? <EditDoctorProfile /> : <Navigate to='/' />} />
      </Route>

      {/* Appointments */}
      <Route path="/appointments/:doctorId" >
        <Route index element={doctorId ? <Patient /> : <Navigate to='/' />} />
        <Route path="bookappointment" >
          <Route index element={doctorId ? <PatientMpin /> : <Navigate to='/' />} />
          <Route path="createpatientmpin" element={doctorId ? <PatientLoginForm /> : <Navigate to='/' />} />
          {/* <Route path="createpatientmpin" element={doctorId ? <CreatePatientMpin /> : <Navigate to='/' />} /> */}
          <Route path="forgetpatientmpin" element={doctorId ? <ForgetPatientMpin /> : <Navigate to='/' />} />
        </Route>
        <Route path="consultation/:reportId" >
          <Route index element={doctorId ? <PatientMedicalReport /> : <Navigate to='/' />} />
          <Route path="summary" element={doctorId ? <Summary /> : <Navigate to='/' />} />
        </Route>
        <Route path="patientprofile/:patientId">
          <Route index element={doctorId ? < GetLoginPatientProfile /> : <Navigate to='/' />} />
          <Route path="booking/:patientId" element={doctorId ? <AppointmentBookingSection /> : <Navigate to='/' />} />
        </Route>
        <Route path="createprofile/:patientId" element={doctorId ? < CreatePatientProfile /> : <Navigate to='/' />} />
        <Route path="patientdata/:patientId" element={doctorId ? <PatientData /> : <Navigate to='/' />} />
      </Route>

      {/* Appointments history */}
      <Route path="/history/:doctorId" >
        <Route index element={doctorId ? < PatientsClinicHistory /> : <Navigate to='/' />} />
        <Route path="report/:reportId" element={doctorId ? <ViewMedicalReport /> : <Navigate to='/' />} />
      </Route>

      {/* subscription */}
      <Route path="/subscription/:doctorId">
        <Route index element={doctorId ? <SubscriptionCard /> : <Navigate to='/' />} />
        <Route path="confirm" element={doctorId ? <SubscriptionConfirmation /> : <Navigate to='/' />} />
      </Route>

      {/* Assistant */}
      <Route path="/helper/:doctorId">
        <Route index element={doctorId ? <Helper /> : <Navigate to='/' />} />
        <Route path="update/:helperId" element={doctorId ? <EditHelper /> : <Navigate to='/' />} />
        <Route path="addhelper" element={<AddHelper />} />
      </Route>

      {/* medicine List */}
      <Route path="/medicinelist/:doctorId" element={doctorId ? <MedicineList /> : <Navigate to='/' />} />
      <Route path="/logout" element={< Logout />} />
      <Route path="/helperlogin" element={<LoginHelper />} />

      {/* lab Work */}
      <Route path="/addlab/:doctorId" element={doctorId ? <AddLabWork /> : <Navigate to='/' />} />
      <Route>
        <Route path="/reports/:doctorId/paymentlist" element={<PaymentList />} />
        <Route path="/reports/:doctorId/labworklist" element={<LabWorkList />} />
        <Route path="/reports/:doctorId/reportlist" element={<Reports />} />
      </Route>
      <Route>
        <Route path="/inventory/:doctorId/inwards" element={<Inwards />} />
        <Route path="/inventory/:doctorId/outwards" element={<Outwards />} />
      </Route>

    </Routes>
  )
}
export default MainContainer;