import React, { useState, useEffect } from 'react';
import { MainInput } from '../../../mainComponent/mainInput';
import { MainButtonInput } from '../../../mainComponent/mainButtonInput';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import HelperApi from '../../../services/HelperApi';
import Toaster from '../../Toaster';
import "react-toastify/dist/ReactToastify.css";
import { setHelper } from '../../../recoil/atom/setHelper';
import { setHelperData } from "../../../recoil/atom/setHelperData";
import { useRecoilState } from "recoil";
import UserLinks from '../../Dashboard-card/partial/uselinks';
import { Wrapper } from '../../../mainComponent/Wrapper';
import { MainNav } from '../../../mainComponent/mainNav';

export default function AddHelper() {
    const { doctorId } = useParams()
    const [helpersData, setHelpersData] = useRecoilState(setHelperData)
    const [accessModule, setAccessModule] = useState([]);
    const [selectedModule, setSelectedModule] = useState([]);
    const [checked, setChecked] = useState([]);
    const [helperData, setHelpeData] = useRecoilState(setHelper);
    const [loginData, setLoginData] = useState([]);
    const {
        getAccessModule,
        createHelper,
        getHelper
    } = HelperApi();

    const navigate = useNavigate()

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setLoginData({ ...loginData, [name]: value });
    }

    useEffect(() => {
        getAccess();
        getHelperDetails()
    }, [])

    const getAccess = () => {
        getAccessModule()
            .then((res) => {
                setAccessModule(res)
            })
    };

    async function getHelperDetails() {
        getHelper(doctorId)
            .then((result) => {
                if (result) {
                    const data = result.filter((helper) => {
                        if (helper.isDeleted === false) {
                            return helper
                        }
                    })
                } else {
                    return null
                }
            })
    }

    const changeSelectedModule = (index) => {
        let newState = [...checked]
        newState[index] = !checked[index]
        setChecked(newState)
        let module = []
        module = [...selectedModule];
        let value = newState[index];
        if (value) {
            module.push({
                moduleId: accessModule[index]._id,
                moduleName: accessModule[index].moduleName
            })
        } else {
            let m = module.filter((item, i) => {
                return (item.moduleId !== accessModule[index]._id)
            })
            module = m
        }
        setSelectedModule(module)
    }

    async function handleSubmit(e) {
        e.preventDefault()
        const bodyData = {
            "doctorId": doctorId,
            "username": loginData.name,
            "password": loginData.password,
            "email": loginData.email,
            "mobile": loginData.mobile,
            "access_module": selectedModule,
        }
        await createHelper(bodyData)
            .then((res) => {
                setHelpeData(helperData.concat(res))
                toast.success("Saved Successfully!")
                navigate(`/helper/${doctorId}`)
            })
    }

    return (
        <Wrapper>
            <MainNav>
                <ul className="clearfix">
                    <li>
                        <Link to={`/helper/${doctorId}`}>
                            <i className="arrow_back backArrow" title="back button"></i>
                        </Link>
                    </li>
                    <li className='float-none' style={{ fontSize: 'inherit' }} >Add Assistant</li>
                </ul>
            </MainNav>
            <div className="row ">
                <div className='width_16'>
                    <div className='dash row'>
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module}
                        />
                    </div>
                </div>
                <div className='width_84'>
                    <form encType='multipart/form-data' onSubmit={handleSubmit}>
                        <div className='white-box'>
                            <div className="row p-4">
                                <div className="col-lg-5 ">
                                    <label htmlFor='name' className='helperLabel font_weight float-left'>User Name</label>
                                    <MainInput
                                        type="text"
                                        name="name"
                                        value={loginData.name}
                                        onChange={handleChange}
                                        placeholder="Enter Your Name">
                                    </MainInput>
                                    <div>
                                        <label htmlFor='password' className='helperLabel font_weight float-left'>Password</label>
                                    </div>
                                    <div>
                                        <MainInput
                                            type="password"
                                            name="password"
                                            value={loginData.password}
                                            onChange={handleChange}
                                            placeholder="Password">
                                        </MainInput>
                                    </div>
                                    <div>
                                        <label htmlFor='email' className='helperLabel font_weight float-left'>Email</label>
                                    </div>
                                    <MainInput
                                        type="email"
                                        name="email"
                                        value={loginData.email}
                                        onChange={handleChange}
                                        placeholder="Email">
                                    </MainInput>
                                    <label htmlFor='mobile' className='helperLabel font_weight float-left'>Mobile Number</label>
                                    <MainInput
                                        type="mobile"
                                        name="mobile"
                                        value={loginData.mobile}
                                        onChange={handleChange}
                                        maxLength={10}
                                        pattern="[+-]?\d+(?:[.,]\d+)?"
                                        placeholder="Phone Number (+XX)">
                                    </MainInput>
                                </div>
                                <div className="col-lg-5">
                                    <div className='helperLabel font_weight helperDiv' align='left'>Select Access</div>
                                    <div className='helperDiv'>
                                        {accessModule && accessModule.map((item, index) => {
                                            return (
                                                <div key={index} className='row'>
                                                    <input
                                                        type="checkbox"
                                                        onChange={() => changeSelectedModule(index)}
                                                        className="mx-3 checkbox-color"
                                                    />
                                                    <label>{item.moduleName}</label>
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="text-center add_top_30 pb-2">
                                <MainButtonInput >Save</MainButtonInput>
                            </div>
                        </div>
                    </form>
                    <div className="row float-right toaster">
                        <Toaster />
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}