import React, { useEffect } from 'react';
import ReportApi from '../../../services/ReportApi';
import { useRecoilState } from 'recoil';
import { setLabFetchData } from '../../../recoil/atom/setLabFetchData';

export default function GetLabPrescription(props) {
    const { reportId } = props;
    const { getLabTestPrescriptionData } = ReportApi();
    const [getlabData, setGetLabData] = useRecoilState(setLabFetchData);

    useEffect(() => {
        getLabPrescriptionData()
    }, []);

    const getLabPrescriptionData = () => {
        getLabTestPrescriptionData({ reportId })
            .then((result) => {
                setGetLabData(result)
            })
    }

    return (
        <>
            {getlabData && getlabData.length > 0 ?
                <div className='margin_top_15' align='left'>
                    <h6  className='patientModal'>List of Test</h6>
                    {getlabData && getlabData.map((item, i) => {
                        return (
                            <span key={i}>
                                {item.test_name}<br />
                            </span>
                        )
                    })}
                </div>
                : null
            }
        </>
    )
}