import React, { useState } from 'react'
import { MainInput } from '../../../mainComponent/mainInput';
import { Button } from 'react-bootstrap'
import LabWorkApi from '../../../services/LabWorkApi';

export default function AddLab(props) {
    const { addLabInfo } = LabWorkApi()
    const [lab, setLab] = useState([])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLab({ ...lab, [name]: value });
    }


    const AddData = () => {
        const bodyData = {
            "labname": lab.name,
            "contact": lab.contact,
        }
        addLabInfo(bodyData)
        props.onClick()
    }

    return (
        <>
            <div className="row">
                <div className="col-md-3">
                    <label className="font_weight">Lab Name</label>
                </div>
                <div className="col-md-7 ">
                    <MainInput
                        type="text"
                        onChange={(e) => handleChange(e)}
                        value={lab.name}
                        name="name"
                        placeholder='Lab name'
                    >
                    </MainInput>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <label className="font_weight ">Lab Contact</label>
                </div>
                <div className="col-md-7 ">
                    <MainInput
                        type="text"
                        onChange={(e) => handleChange(e)}
                        value={lab.contact}
                        maxLength={10}
                        pattern="[+-]?\d+(?:[.,]\d+)?"
                        name="contact"
                        placeholder='Contact'
                    >
                    </MainInput>
                </div>
            </div>

            <Button
                type="submit"
                onClick={AddData}
                variant="default"
                className='appColor btn_sub mr-3'>
                Add
            </Button>

        </>
    )
}