import React, { useEffect, useState } from 'react';
import ReportApi from '../../../services/ReportApi';
import { useRecoilState } from 'recoil';
import { setPatientGeneralInfo } from '../../../recoil/atom/setPatientGeneralInfo';

const FetchPatientGeneralInfo = (props) => {
    const { reportId } = props;
    const { getMedicineReport } = ReportApi();
    const [generalInfo, setGeneralInfo] = useRecoilState(setPatientGeneralInfo)

    useEffect(() => {
        generalData()
    }, [])

    const generalData = () => {
        getMedicineReport({ reportId })
            .then((res) => {
                setGeneralInfo(res[0])
            })
    }

    return (
        <>
            {generalInfo ?
                <div className="patientDataStyle">
                    <div className='row'>
                        {generalInfo.age ?
                            <span className='col-md-6'>
                                <label className="mx-2 patientName ">Age :  </label>
                                {generalInfo.age}
                            </span>
                            : null}

                        {generalInfo.weight ?
                            <span className='col-md-6'>
                                <label className="mx-2 patientName ">Weight : </label>
                                {generalInfo.weight}
                            </span>
                            : null}

                        {generalInfo.height ?
                            <span className='col-md-6'>
                                <label className="mx-2 patientName ">Height : </label>
                                {generalInfo.height}
                            </span>
                            : null}


                        {generalInfo.BMI ?
                            <span className='col-md-6'>
                                <label className="mx-2 patientName ">BMI : </label>
                                {generalInfo.BMI}
                            </span>
                            : null}

                        {generalInfo.temp ?
                            <span className='col-md-6'>
                                <label className="font_weight mx-2">Temperature :</label>
                                {generalInfo.temp}
                            </span>
                            : null}

                        {generalInfo.bp ?
                            <span className='col-md-6'>
                                <label className="mx-2 patientName ">Blood Pressure :</label>
                                {generalInfo.bp}
                            </span>
                            : null}

                        {generalInfo.pulse ?
                            <span className='col-md-6'>
                                <label className="mx-2 patientName ">Pulse :</label>
                                {generalInfo.pulse}
                            </span>
                            : null}


                        {generalInfo.problem ?
                            <span className='col-md-6'>
                                <label className="mx-2 patientName ">Problem :</label>
                                {generalInfo.problem}
                            </span>
                            : null}

                    </div>
                </div>
                : null}
        </>
    )


}
export default FetchPatientGeneralInfo;