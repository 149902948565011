import { useState, useEffect } from "react";
import AuthApi from "../../../../services/AuthApi";
import Report from "../../../Dashboard-card/Report";

function FetchDoctorPersonalDetails(props) {
    const { doctorId } = props;
    const [fetchPersonalData, setFetchPersonalData] = useState(null);
    const { getDrInfo } = AuthApi();

    useEffect(() => {
        getDoctorPersonalDetails();
    }, [])

    const getDoctorPersonalDetails = () => {
        getDrInfo({ doctorId })
            .then((result) => {
                setFetchPersonalData(result.result[0]);
            })
    }

    return (
        <div className="col-lg-4" >
            {fetchPersonalData ?
                <div className="white-box minHeight" align='center' key={fetchPersonalData.id}>
                    <div >
                        <img
                            src={fetchPersonalData.photo}
                            alt="doctorProfile"
                            className='doctorPic borderRadius'
                        />
                        <div className="margin_top_15 underline" >
                            <h5>Dr. {fetchPersonalData.name}</h5>
                            <div className="">
                                <div  >
                                    <label className="patientName">
                                        {fetchPersonalData.educationList[0] ? fetchPersonalData.educationList[0].specialization : null}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <Report doctorId={doctorId} />
                    </div>
                </div> :
                <div>Doctor information not available</div>
            }
        </div>
    )
}
export { FetchDoctorPersonalDetails }