import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate, } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { setDoctorId } from '../../../recoil/atom/setDoctorId';
import { setHelperData } from '../../../recoil/atom/setHelperData';
import { MainNav } from '../../../mainComponent/mainNav';
import UserLinks from '../../Dashboard-card/partial/uselinks';
import { Wrapper } from '../../../mainComponent/Wrapper';
import ReportApi from '../../../services/ReportApi';
import { Button, Modal } from 'react-bootstrap';
import Payment from './Payment';
import PrescriptionSummary from './PrescriptionSummary';
import { Drawer, IconButton, Typography, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MainButtonInput } from '../../../mainComponent/mainButtonInput';
import AppointmentsApi from '../../../services/AppointmentsApi';
import CompletePayment from './CompletePayment';

export default function Summary() {
    const { reportId } = useParams();
    const { getMedicineReport } = ReportApi();
    const [helpersData] = useRecoilState(setHelperData)
    const [doctorId] = useRecoilState(setDoctorId)
    const [appointmentId, setAppointmentId] = useState()
    const [paymentStatus, setPaymentStatus] = useState([])
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [completeShow, setCompleteShow] = useState(false);
    const isMobile = useMediaQuery('(max-width:  768px)')
    const navigate = useNavigate()
    const {
        UpdateStatusBookingdata,
        getPaymentData,
        downloadPrescription
    } = AppointmentsApi()

    useEffect(() => {
        getMedicineReportData()
        // getAppointmentByID()
    }, [])

    const handleClose = () => {
        setShow(false);
        setOpen(false);
    }
    const handleShow = () => {
        setShow(true);
        setOpen(true);
    }

    const handleCompleteShow = (item) => {
        setCompleteShow(true)
    }

    const handleCompleteClose = () => {
        setCompleteShow(false)
        navigate(`/history/${doctorId}`)
    }

    const getMedicineReportData = () => {
        getMedicineReport({ reportId })
            .then((res) => {
                if (res) {
                    setAppointmentId(res[0].patientAppointmentId)
                    getPaymentData(res[0].patientAppointmentId)
                        .then(data => {
                            setPaymentStatus(data[0].payment)
                        })
                }
            })
    }

    const onSubmit = () => {
        handleClose();
    };

    const handleClick = () => {
        const bodyData = {
            "status": "Completed",
            "medicalReportId": reportId,
            "payment": paymentStatus,
        }
        UpdateStatusBookingdata({ appointmentId }, bodyData)
        handleCompleteClose()
    }

    const PDFExport = () => {
        downloadPrescription(reportId)
        .then((result) => {
            let alink = document.createElement('a');
            alink.href = result;
            alink.setAttribute("target", "_blank")
            alink.download = 'Prescription.pdf';
            alink.click();
        })
    }
    return (
        <Wrapper>
            <MainNav>
                <ul className="clearfix">
                    <li>
                        <Link to={`/appointments/${doctorId}/consultation/${reportId}`}>
                            <i className="arrow_back backArrow" title="back button"></i>
                        </Link>
                    </li>
                    <li className='float-none' style={{ fontSize: 'inherit' }}>Summary</li>
                </ul>
            </MainNav>
            <div className="row">
                <div className='width_16'>
                    <div className='dash row'>
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module}
                        />
                    </div>
                </div>
                <div className='width_84'>
                    <div className="common_box ">
                        <div className='row  justify-end' >
                            <div className='mr-2'>
                                <MainButtonInput onClick={handleCompleteShow}>Complete Appointment</MainButtonInput>
                            </div>
                            <div className='mr-2' >
                                <MainButtonInput onClick={handleShow}>Make Payment</MainButtonInput>
                            </div>
                            <div className='mr-2' >
                                <Button
                                    type="submit"
                                    onClick={PDFExport}
                                    variant="default"
                                    className='lightbuttonColor  mr-3 btn_sub'>
                                    <i className="icon-download-1" style={{ fontSize: 15 }} />
                                </Button>
                            </div>
                        </div>
                        <PrescriptionSummary reportId={reportId} />
                    </div>

                </div>
            </div>
            {/* {isMobile ? (
                <>
                    {(paymentStatus === "Pending") ?
                        <Drawer anchor="bottom" open={open} onClose={handleClose}>
                            <div className='drawerTitle underline' >
                                <Typography variant="h6">Payment</Typography>
                                <IconButton onClick={handleClose} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                            </div>
                            <div className='p-4'>
                                <CompletePayment
                                    doctorId={doctorId}
                                    appointmentId={appointmentId}
                                    reportId={reportId}
                                    onSubmit={handleClose}
                                />
                            </div>
                        </Drawer>
                        :
                        <Drawer anchor="bottom" open={open} onClose={handleClose}>
                            <div className='drawerTitle underline' >
                            <Typography variant="h6">Payment</Typography>
                            <IconButton onClick={handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </div>
                            <div className='p-4'>
                                <Payment
                                    doctorId={doctorId}
                                    appointmentId={appointmentId}
                                    reportId={reportId}
                                    onSubmit={onSubmit}
                                />
                            </div>
                        </Drawer>
                    }
                </>
            ) : (
                <> */}
            <>
                {(paymentStatus === "Pending") ?
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Payment</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <CompletePayment
                                doctorId={doctorId}
                                appointmentId={appointmentId}
                                reportId={reportId}
                                onSubmit={handleClose}
                            />
                        </Modal.Body>
                    </Modal>
                    :
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Payment</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Payment
                                doctorId={doctorId}
                                appointmentId={appointmentId}
                                reportId={reportId}
                                onSubmit={onSubmit}
                            />
                        </Modal.Body>
                    </Modal>
                }
            </>
            {/* </>
            )} */}
            <Modal show={completeShow} onHide={handleCompleteClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="alert"
                        style={{ backgroundColor: '#e1e8ed', fontSize: 20 }}>
                        You want to Complete this Appointment.
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className='appColor' onClick={handleClick}>
                        Yes
                    </Button>
                    <Button variant="default" className="borderStyle" onClick={handleCompleteClose}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </Wrapper >
    )
}