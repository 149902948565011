import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Link, useNavigate, useParams } from "react-router-dom";
import PatientPersonalInfo from './partial/PatientPersonalInfo';
import MedicinePrescription from './partial/MedicinePrescription';
import NewFollowup from './partial/NewFollowup';
import Symptoms from './partial/Symptoms';
import LabPrescription from './partial/LabPrescription';
import { MainNav } from '../../mainComponent/mainNav';
import { useLocation } from 'react-router-dom';
import { Wrapper } from '../../mainComponent/Wrapper';
import UserLinks from '../Dashboard-card/partial/uselinks';
import { setHelperData } from "../../recoil/atom/setHelperData";
import { useRecoilState } from "recoil";
import { setDoctorId } from '../../recoil/atom/setDoctorId';
import DentalServicesPriscription from './partial/dentalservicesprescription';
import Premedication from './partial/Premedication';
import FileUpload from './partial/FileUpload';

function TabPanel(props) {
    const { children, value, index } = props;
    return (
        <div>
            {value === index && (
                <Box div={6}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function PatientMedicalReport() {
    const { reportId } = useParams();
    const [doctorId, setDoctorsId] = useRecoilState(setDoctorId)
    const [helpersData, setHelpersData] = useRecoilState(setHelperData)
    const { state } = useLocation()
    const navigate = useNavigate()
    //for tab
    const [value, setValue] = useState(0);
    const handleChange = (e, newValue) => {
        e.preventDefault();
        setValue(newValue);
    };

    function changeTab(tabIndex) {
        setValue(tabIndex);
    }

    const goBack = () => {
        navigate(-1)
    }

    return (
        <Wrapper>
            <MainNav>
                <ul className="clearfix">
                    <li>
                        {/* <Link to={`/appointments/${doctorId}`}> */}
                        <Link onClick={goBack}>
                            <i className="arrow_back backArrow" title="back button"></i>
                        </Link>
                    </li>
                    <li
                        className='float-none'
                        style={{ fontSize: 'inherit' }}>
                        Consultation
                    </li>
                </ul>
            </MainNav>
            <div className='row'>
                <div className='width_16'>
                    <div className='dash '>
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module}
                        />
                    </div>
                </div>
                <div className='width_84'>
                    <div className="common_box">
                        <div className="white-box pb-5">
                            <Paper square>
                                <Tabs value={value} variant='scrollable' onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary">
                                    <Tab label="General Info" />
                                    <Tab label="Symptoms" />
                                    <Tab label="Treatment" />
                                    <Tab label="Medicines" />
                                    <Tab label="Tests" />
                                    <Tab label="Premedication" />
                                    <Tab label="Upload Documents" />
                                    <Tab label="Follow up" />
                                </Tabs>
                            </Paper>
                            <div className="tablecontent">
                                <TabPanel value={value} index={0}>
                                    <PatientPersonalInfo
                                        reportId={reportId}
                                        onChange={() => changeTab(1)}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <Symptoms
                                        reportId={reportId}
                                        onChange={() => changeTab(2)}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <DentalServicesPriscription
                                        reportId={reportId}
                                        doctorId={doctorId}
                                        onChange={() => changeTab(3)}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={3}>
                                    <MedicinePrescription
                                        reportId={reportId}
                                        doctorId={doctorId}
                                        onChange={() => changeTab(4)}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={4}>
                                    <LabPrescription
                                        reportId={reportId}
                                        onChange={() => changeTab(5)}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={5}>
                                    <Premedication
                                        doctorId={doctorId}
                                        reportId={reportId}
                                        onChange={() => changeTab(6)}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={6}>
                                    <FileUpload
                                        doctorId={doctorId}
                                        reportId={reportId}
                                        onChange={() => changeTab(7)}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={7}>
                                    <NewFollowup
                                        fees={state}
                                        doctorId={doctorId}
                                        reportId={reportId}
                                    />
                                </TabPanel>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Wrapper >
    )
}