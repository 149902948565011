import React, { useEffect, useState } from "react";
import { MainInput } from "../../../mainComponent/mainInput";
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import ServicesApi from "../../../services/servicesAPI";
import { useRecoilState } from "recoil";
import { setAddTreatment } from "../../../recoil/atom/setAddTreatement";

const UpdateTreatment = (props) => {
    const { doctorId, serviceId } = props
    const [ treatment, setTreatment] = useState([]);
    const [ updateTreatment, setUpdateTreatment] = useRecoilState(setAddTreatment);
    const { updateServices, getServicesById } = ServicesApi()

    useEffect(()=>{
        getService()
    },[])

    function handleChange(event) {
        const { name, value } = event.target;
        setTreatment(prevInput => {
            return {
                ...prevInput,
                [name]: value
            }
        })
    }

    const getService = () =>{
        getServicesById(serviceId)
        .then((res)=>{
            setTreatment(res)
        })
    }

    const Save = (e) => {
        e.preventDefault();
        const bodyData = {
            doctorId: doctorId,
            name: treatment.name,
            fees: treatment.fees
        }

        updateServices(serviceId, bodyData)
            .then((res) => {
                const newData = updateTreatment.map(function (d, index) {
                    if (serviceId === d._id) {
                        return res
                    } else {
                        return d
                    }
                })
                setUpdateTreatment(newData)
            });
        props.onSubmit()
    }

    return (
        <div className="width_100">
            <form onSubmit={Save}>
                <div className="form-group">
                    <label className="font_weight">Treatment Name</label>
                    <MainInput
                        type="text"
                        name="name"
                        onChange={handleChange}
                        value={treatment.name}
                        placeholder="Enter Treatment name">
                    </MainInput>
                </div>

                <label className="font_weight">Fees</label>
                <MainInput
                    type="text"
                    name="fees"
                    value={treatment.fees}
                    onChange={handleChange}
                    placeholder="Enter fees">
                </MainInput>

                <div className="text-center m-3">
                    <MainButtonInput value="Save" />
                </div>
            </form >
        </div >
    );
};
export { UpdateTreatment }
