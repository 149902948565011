import React, { useState, useEffect } from 'react';
import { KEYID, SECRETEKEY } from '../../../config'
import { Button, Modal } from "react-bootstrap";
import AppointmentsApi from '../../../services/AppointmentsApi';
import ReportApi from '../../../services/ReportApi';
import ServicesApi from '../../../services/servicesAPI';
import { MainButtonInput } from '../../../mainComponent/mainButtonInput';
import RenderRazorpay from '../RenderRazorpay/RenderRazorPay';
import AuthApi from '../../../services/AuthApi';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { setFetchSymptoms } from '../../../recoil/atom/setFetchSymptoms';
import { setLabFetchData } from '../../../recoil/atom/setLabFetchData';
import { setFetchTreatment } from '../../../recoil/atom/setFetchTreatment';
import { setMedicineData } from '../../../recoil/atom/setMedicineData';

export default function Payment(props) {
    const { reportId, appointmentId, doctorId } = props;
    const [total, setTotal] = useState(0);
    const [patientFees, setPatientFees] = useState(0);
    const [paidFees, setPaidFees] = useState();
    const [getServices, setGetServices] = useState(null);
    const [doctorName, setDoctorName] = useState(null);
    const [bookingInfo, setBookingInfo] = useState(null);
    const [show, setShow] = useState(false);
    const [fetchSymptoms, setfetchSymptoms] = useRecoilState(setFetchSymptoms)
    const [getlabData, setGetLabData] = useRecoilState(setLabFetchData);
    const [fetchTreatment, setFetchtreatment] = useRecoilState(setFetchTreatment)
    const [medicineData, setMedicinedata] = useRecoilState(setMedicineData)
    const { getDrInfo } = AuthApi()
    const [displayRazorpay, setDisplayRazorpay] = useState(false);
    const { UpdateStatusBookingdata, createPDF, getPaymentData } = AppointmentsApi()
    const { getDentalServicesPrescription } = ReportApi();
    const { payment, paymentCapture } = ServicesApi()
    const navigate = useNavigate()
    const [orderDetails, setOrderDetails] = useState({
        orderId: null,
        currency: null,
        amount: null,
        payId: null
    });

    const pendingAmount = (parseInt(patientFees) + parseInt(total)) - parseInt(paidFees)

    useEffect(() => {
        getServicesData()
        paymentdata()
        getDoctorPersonalDetails()
    }, [])

    const handleShow = () => {
        setShow(true)
    }
    const handleClose = () => setShow(false)

    const paymentdata = () => {
        getPaymentData(appointmentId)
            .then(data => {
                if (data) {
                    setBookingInfo(data[0])
                    setPatientFees(data[0]["fees"])
                }
            })
    }
    const getDoctorPersonalDetails = () => {
        getDrInfo({ doctorId })
            .then((result) => {
                if (result) {
                    setDoctorName(result.result[0]["name"]);
                }
            })
    }

    const getServicesData = () => {
        getDentalServicesPrescription({ reportId })
            .then((result) => {
                if (result) {
                    setGetServices(result)
                    const totalAmt = result.reduce((initialValue, curValue) => {
                        return initialValue + parseInt(curValue.fees)
                    }, 0)
                    setTotal(totalAmt)
                }
            })
    }

    const handlePaidFeesValue = (e) => {
        e.preventDefault();
        setPaidFees(e.target.value)
    }

    const paymentOrderData = () => {
        const bodyData = {
            amount: paidFees * 100,
            appointmentId: appointmentId,
            transactionId: `pay_${appointmentId}`,
        }
        payment(bodyData)
            .then((data) => {
                const bodyData = {
                    "status": bookingInfo.status,
                    "payment": pendingAmount > 0 ? "Pending" : "Done",
                    "medicalReportId": reportId,
                    "paymentId": data.id,
                }
                UpdateStatusBookingdata({ appointmentId }, bodyData)
                const details = {
                    "totalAmount": parseInt(patientFees) + parseInt(total),
                    "pendingamount": pendingAmount,
                    "doctorId": doctorId,
                    "orderId": data.order_id,
                    "paymentId": `pay_${appointmentId}`,
                    "signature": appointmentId,
                    "refId": appointmentId,
                    "status": pendingAmount > 0 ? "Partial" : "Completed",
                    "paymentMethod": "cash",
                    "_id": data.id //update payment 
                }
                paymentCapture(details)
            })
        //createPDF(reportId)
        setfetchSymptoms('')
        setGetLabData('')
        setFetchtreatment('')
        setMedicinedata('')
        handleClose()
        navigate(`/history/${doctorId}`)
    }

    const handleCreateOrder = async () => {
        createPDF(reportId)
        const bodyData = {
            amount: paidFees * 100,
            appointmentId: appointmentId,
            transactionId: ` ${appointmentId}_paycash`,
        }
        payment(bodyData)
            .then((data) => {
                if (data && data.order_id) {
                    setOrderDetails({
                        payId: data.id,
                        orderId: data.order_id,
                        currency: data.currency,
                        amount: data.amount,
                    });
                    setDisplayRazorpay(true);
                };
            })

    }
    return (
        <>
            {bookingInfo ? (
                <div className='paymentInput'>
                    <label> Consultation Fees : </label>
                    <span className='ml-2'>{bookingInfo.fees}</span>
                </div>
            ) : null}

            {getServices && getServices.map((item, i) => {
                return (
                    <div key={i} className='paymentInput'>
                        <label > {item.service_name} : </label>
                        <span className='ml-2'>{item.fees}</span>
                    </div>)
            })}

            <div className='border-payment' />

            <div className='paymentInput'>
                <label className='mt-2'>Total :</label>
                <span className="mt-2 ml-2">{parseInt(patientFees) + parseInt(total)}</span>
            </div>

            <div className=' paymentInput'>
                <label className='otherFees'>Enter Amount which you pay now : </label>
                <input
                    type="text"
                    value={paidFees}
                    onChange={handlePaidFeesValue}
                    className="payment otherInput"
                    name="OtherFees"
                />
            </div>

            <div className=' paymentInput'>
                <label className='otherFees'>Pending Amount :</label>
                <span className='ml-2'>{(parseInt(patientFees) + parseInt(total)) - parseInt(paidFees)}</span>
            </div>

            <div className='border-payment' />

            <div className='row mt-3'>
                <div className="text-center">
                    <MainButtonInput
                        onClick={() => handleShow()}>
                        Cash Payment
                    </MainButtonInput>
                </div>

                <div className="ml-2 text-center">
                    <div
                        className="disabled-div">
                        <label>Online Payment</label>
                    </div>
                    {/* <MainButtonInput
                        disabled={true}
                        onClick={handleCreateOrder}>
                        Online Payment
                    </MainButtonInput> */}
                </div>

                {displayRazorpay === true && (
                    <RenderRazorpay
                        payId={orderDetails.payId}
                        amount={orderDetails.amount}
                        currency={orderDetails.currency}
                        orderId={orderDetails.orderId}
                        keyId={KEYID}
                        keySecret={SECRETEKEY}
                        doctorName={doctorName}
                        reportId={reportId}
                        appointmentId={appointmentId}
                        refId={appointmentId}
                        doctorId={doctorId}
                        totalAmount={parseInt(patientFees) + parseInt(total)}
                        pendingamount={(parseInt(patientFees) + parseInt(total)) - parseInt(paidFees)}
                    />
                )}
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="alert " style={{ backgroundColor: '#e1e8ed', fontSize: 20 }}>Did you receive complete payment?</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className='appColor' onClick={() => paymentOrderData()}>
                        Yes
                    </Button>
                    <Button variant="default" className='lightbuttonColor borderStyle' onClick={() => paymentOrderData()}>
                        Due
                    </Button>
                    <Button variant="default" className='lightbuttonColor borderStyle' onClick={handleClose}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}