import React, { useEffect, useState, useRef } from 'react';
import { Link, Outlet, useNavigate } from "react-router-dom";
import moment from 'moment';
import { Button, Modal } from 'react-bootstrap';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import { FaClinicMedical } from 'react-icons/fa';
import AppointmentsApi from '../../services/AppointmentsApi';
import ReportApi from '../../services/ReportApi';
import ReactPaginate from 'react-paginate';
import { useRecoilState } from 'recoil';
import { setReportsId } from '../../recoil/atom/setReportId';
import Loader from './Loader';
import { MainButtonInput } from '../../mainComponent/mainButtonInput';
import { SecondaryButtonInput } from '../../mainComponent/secondaryButtonInput';
import { setFollowupSlots } from '../../recoil/atom/setFollowupSlots';
import { MainSelect } from '../../mainComponent/mainSelect';
import AuthApi from '../../services/AuthApi';
import { THEME_COLOR } from '../../config';

export default function PatientList(props) {
    const { doctorId } = props;
    const navigate = useNavigate();
    const [patientList, setPatientList] = useState(null);
    const [show, setShow] = useState(false);
    const [id, setId] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0);
    const [clinics, setClinics] = useState([]);
    const [selectedClinic, setSelectedClinic] = useState(null)
    const [reportId, setReportId] = useRecoilState(setReportsId)
    const { MedicineReportData, } = ReportApi()
    const { getDrInfo } = AuthApi()
    const [isLoading, setIsLoading] = useState(true);
    const [followupSlots, setFollowupSlot] = useRecoilState(setFollowupSlots);
    const { getAppointmentsDetails, cancelPatientAppointment, updateIncompleteStatus } = AppointmentsApi()
    const pageSize = 6;
    const clinicRef = useRef(selectedClinic);
    const paginationRef = useRef(currentPage);

    useEffect(() => {
        getPatientDetails(selectedClinic, currentPage);
        getAllClinics();
    }, []);

    const handleCancelShow = (details) => {
        setId(details._id)
        setShow(true)
    }

    const handleClose = () => setShow(false)

    function saveData(item) {
        const patientId = item.dependentId ? item.dependentId : item.patientId
        const bodyData = {
            "doctorId": doctorId,
            "patientId": patientId,
            'patientAppointmentId': item._id,
            'clinicId': item.clinicId,
            "fees": item.fees,
        }
        MedicineReportData(bodyData)
            .then((res) => {
                setFollowupSlot(res)
                setReportId(res._id)
                const bodyData = {
                    'status': "Incomplete",
                }
                updateIncompleteStatus(item._id, bodyData)
                navigate(`consultation/${res._id}`, { state: item.fees })
            })
    }

    const getAllClinics = () => {
        getDrInfo({ doctorId })
            .then((jsonRes) => {
                const clinicsData = jsonRes.result[0]["clinicList"]
                setClinics(clinicsData)
            })
    }

    function getPatientDetails(selectedClinic, currentPage) {
        setIsLoading(true);
        const data = {
            page: currentPage,
            pageSize: pageSize,
            status: "Ongoing",
            selectedClinic: selectedClinic
        }
        getAppointmentsDetails(doctorId, data)
            .then((result) => {
                setPatientList(result.pageIndex);
                setTotalPages(result.totalPages);
                if (result.result) {
                    result.result.forEach((data) => {
                        const patientAppointmentId = data._id;
                        const currentDate = moment().format("YYYY-MM-DD HH:mm");
                        const slotDate = moment(data.selectedDate).format("YYYY-MM-DD") + " " + data.slotTime;
                        if (slotDate < currentDate) {
                            const bodyData = {
                                status: "Incomplete"
                            };
                            updateIncompleteStatus(patientAppointmentId, bodyData);
                        }
                    });
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }


    function cancelAppointment(id) {
        cancelPatientAppointment(id)
            .then(() => {
                getPatientDetails(selectedClinic, currentPage)
                handleClose()
            })
    }

    const handlePageClick = (data) => {
        paginationRef.current = data.selected + 1;
        setCurrentPage(data.selected + 1)
        getPatientDetails(selectedClinic, data.selected + 1);
    }
    const handleShowProfile = (e, patientId) => {
        e.preventDefault()
        navigate(`patientdata/${patientId}`)
    }

    const handleClinicChange = (e) => {
        clinicRef.current = e.target.value;
        setSelectedClinic(e.target.value);
        getPatientDetails(e.target.value, currentPage)
    };

    return (
        <>
            {isLoading === true ?
                <div className='loader-container'>
                    <Loader />
                </div>
                :
                <>

                    <div className="white-box mb-2">
                        <div className='row' align='left'>
                            <div className='mr-4'>
                                <label className='mb-2 font_weight' value="lab">Find Appointments by Clinic</label>
                                <div className='mt-2'>
                                    <MainSelect value={selectedClinic} onChange={handleClinicChange}>
                                        <option value="">Select Clinic</option>
                                        {clinics && clinics.map((item, index) => (
                                            <option key={index} value={item.clinicName} className="form-control">{item.clinicName}</option>
                                        ))}
                                    </MainSelect>
                                </div>
                            </div>
                        </div>
                    </div>
                    {patientList && patientList.length > 0 ?
                        <>
                            <div className='row'>
                                {patientList.map((details, i) => {
                                    return (
                                        <div key={i} className="col-md-4">
                                            {!details.dependentId ?
                                                <div className="cardDiv">
                                                    <span className='cardSpan'>
                                                        <i className='icon-user color patientListIcon' />
                                                        <span className='patientName'>
                                                            <Link to="#" className='underLine' onClick={(e) => handleShowProfile(e, details.patientId)}>
                                                                {details['patientDetails'][0].name}
                                                            </Link>
                                                        </span>
                                                    </span>
                                                    <span className='cardSpan'>
                                                        <i className='icon-mobile-1 color patientListIcon' />
                                                        <span className='patinetInfo'>{details['patientDetails'][0].mobile}</span>
                                                    </span>
                                                    <span className='cardSpan '>
                                                        <i className=' color patientListIcon ml-1 mr-2' ><FaClinicMedical /> </i>
                                                        <span className='patinetInfo '> {details['clinicList'].clinicName}</span>
                                                    </span>
                                                    <span className='cardSpan time'>
                                                        <i className='pe-7s-date m-1 color patientListIcon' />
                                                        <span className='slotTime'>
                                                            {moment(details.selectedDate).format('YYYY-MM-DD').toString()},
                                                            {details.slotTime}
                                                        </span>
                                                    </span>
                                                    <span className='cardSpan'>
                                                        <AccessTimeRoundedIcon style={{ fontSize: 22, marginTop: 5, marginLeft: 2, color: THEME_COLOR }} />
                                                        {details.timeSlot} Min.
                                                    </span>
                                                    <div className="row justify-end top_border">
                                                        <div className='mt-3'>
                                                            <Link onClick={() => saveData(details)}>
                                                                <MainButtonInput>Start Consultation</MainButtonInput>
                                                            </Link>
                                                        </div>
                                                        <div className='mt-3 ml-2'>
                                                            <Link to="#" onClick={() => handleCancelShow(details)} >
                                                                <SecondaryButtonInput>Cancel</SecondaryButtonInput>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>

                                                :
                                                <div className="cardDiv">
                                                    <div className='cardSpan row'>
                                                        <div align='left' className='width_70' >
                                                            <i className=' icon-user color patientListIcon' />
                                                            <span className='patientName'>{details['dependentDetails'][0].name}</span>
                                                        </div>
                                                        <div className='width_10' >
                                                            <span className='dependent'>Dependent</span>
                                                        </div>
                                                    </div>
                                                    <span className='cardSpan'>
                                                        <i className='icon-mobile-1 color patientListIcon' />
                                                        <span className='patinetInfo'>{details['patientDetails'][0].mobile}</span>
                                                    </span>
                                                    <span className='cardSpan '>
                                                        <i className=' color patientListIcon ml-1 mr-2' ><FaClinicMedical /> </i>
                                                        <span className='patinetInfo '> {details['clinicList'].clinicName}</span>
                                                    </span>
                                                    <span className='cardSpan time'>
                                                        <i className='pe-7s-date m-1 color patientListIcon' />
                                                        <span className='slotTime'>
                                                            {moment(details.selectedDate).format('YYYY-MM-DD').toString()},{details.slotTime}
                                                        </span>
                                                    </span>
                                                    <span className='cardSpan'>
                                                        <AccessTimeRoundedIcon style={{ fontSize: 22, marginTop: 5, marginLeft: 2, color: THEME_COLOR }} />
                                                        {details.timeSlot} Min.
                                                    </span>
                                                    <div className="row justify-end top_border">
                                                        <div className="mt-3">
                                                            <Link onClick={() => saveData(details)}>
                                                                <MainButtonInput>Start Consultation</MainButtonInput>
                                                            </Link>
                                                        </div>
                                                        <div className="mt-3 ml-3">
                                                            <Link to="#" onClick={() => handleCancelShow(details)} >
                                                                <SecondaryButtonInput>Cancel</SecondaryButtonInput>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='marginleft'>
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="Next >"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={totalPages}
                                    previousLabel="< Previous"
                                    renderOnZeroPageCount={null}
                                    marginPagesDisplayed={2}
                                    containerClassName="pagination"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    activeClassName="active"
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    forcePage={currentPage - 1}
                                />
                            </div>
                        </> :
                        <div className="clinicHistory font_weight" >Appointments are not Available</div>
                    }
                </>
            }

            <Modal show={show} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        className="alert"
                        style={{ backgroundColor: '#e1e8ed', fontSize: 20 }}>
                        You want to cancle this appointment.
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className='appColor' onClick={() => cancelAppointment(id)}>
                        Yes
                    </Button>
                    <Button variant="default" className='borderStyle' onClick={handleClose}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal >
            <Outlet />
        </>
    )
}