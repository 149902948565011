import React, { useState } from "react";
import { FetchExperience } from "./Partial/fetchExperience";
import { AddDoctorProfessionalExperience } from "./Partial/addDoctorProfessionalExperience";
import { Link } from 'react-router-dom';
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import { SecondaryButtonInput } from "../../../mainComponent/secondaryButtonInput";
import { Modal } from "react-bootstrap";
import { Drawer, IconButton, Typography, useMediaQuery } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

function DoctorProfessionalExperience(props) {
    const { doctorId } = props;
    const [showExperience, setShowExperience] = useState(true);
    const [open, setOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:768px)')

    function handleAdd() {
        setShowExperience(!showExperience)
        setOpen(!open)
    }

    function handleRecordAdded() {
        setShowExperience(true)
        setOpen(false)
    }

    return (
        <>
            <FetchExperience doctorId={doctorId} />
            <div className="row justify-end">
                <div className="my-2 ">
                    <Link onClick={() => handleAdd()}>
                        <MainButtonInput >Add</MainButtonInput>
                    </Link>
                </div>
                <div className="m-2">
                    <SecondaryButtonInput onClick={props.data}>Next</SecondaryButtonInput>
                </div>
            </div>
            <>
                {isMobile ? (
                    <Drawer anchor="bottom" open={open} onClose={handleRecordAdded}>
                        <div className='drawerTitle underline' >
                            <Typography variant="h6">Add Experience</Typography>
                            <IconButton onClick={handleRecordAdded} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div className='p-4'>
                            <AddDoctorProfessionalExperience
                                doctorId={doctorId}
                                addRecords={handleRecordAdded} />
                        </div>
                    </Drawer>
                ) : (
                    <Modal show={showExperience === false} onHide={handleRecordAdded}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add Experience</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <AddDoctorProfessionalExperience
                                doctorId={doctorId}
                                addRecords={handleRecordAdded} />
                        </Modal.Body>
                    </Modal>
                )
                }
            </>
        </>
    )
}
export { DoctorProfessionalExperience }