import React from 'react';
import { useEffect, useState } from "react";
import AppointmentsApi from '../../services/AppointmentsApi';

export default function Report(props) {
    const { doctorId } = props
    const [patientList, setPatientList] = useState([]);
    const { getAppointmentsDetails } = AppointmentsApi();
    const [patientData, setPatientData] = useState([]);

    useEffect(() => {
        getPatientDetails();
    }, [])

    function getPatientDetails() {
        getAppointmentsDetails(doctorId)
            .then((result) => {
                if (result.test) {
                    const data = result.test.filter((patient) => {
                        if (patient.status === "Completed") {
                            return patient;
                        }
                    })
                    setPatientList(data)
                    const res = result.test.filter(function (item, index) {
                        return index === result.test.findIndex((obj) => {
                            if (item.patientId === obj.patientId)
                                return item
                        })
                    })
                    setPatientData(res)
                    // const item = result.test.filter((res) => {
                    //     if (res.payment === "Done")
                    //         return res
                    // })
                    // const total = item["paymentList"].reduce((initialValue, curValue) => {
                    //     return initialValue + parseInt(curValue.amount)
                    // }, 0)
                    // setTotal(total)
                } else {
                    return null
                }
            })
    }

    return (
        < div className='row'>
            <div className="col-md-6 ">
                <div className="reportDiv color">
                    <div className=' fontS'>{patientList.length}</div>
                    <div className='fontWeight'> Appointments</div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="reportDiv color">
                    <div className=' fontS'>{patientData.length}</div>
                    <div className='fontWeight'>Total Patients</div>
                </div>
            </div>
            {/* <div className="col-md-4 ">
                        <div className="cardDiv">
                            <span className='cardSpan '>
                                <span className='patientName '>Total Amount (INR)</span>
                                <span className='patientName '>{total}</span>
                            </span>
                        </div>
                    </div> */}
        </div >
    )
}