import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Topbar = (props) => {
    return (
        <div>
            <div
                style={{ padding: 6, backgroundColor: '#d7eaf5' }}
                expandIcon={<ExpandMoreIcon />}
            >
                <Typography
                    className="m-2"
                    align='left'
                    style={{ color: 'black' }}>
                    <b><h5>{props.icon}  {props.title}
                        {props.fees}</h5></b>
                </Typography>
                <Typography
                    className="ml-4"
                    align='left'
                    style={{ color: 'black' }}>
                    {props.address}
                </Typography>
            </div>
            <div>
                <Typography>
                    {props.children}
                </Typography>
            </div>
        </div>
    )
}
export { Topbar }