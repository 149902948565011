import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import { MainInput } from "../../../mainComponent/mainInput";
import { useRecoilState } from "recoil";
import { setDoctorId } from "../../../recoil/atom/setDoctorId";
import PatientApi from "../../../services/PatientApi";
import { setNewPatientId } from "../../../recoil/atom/setNewPatientId";

export default function CreatePatientMpin(props) {
    const { loginData } = props;
    const [isError, setIsError] = useState(false);
    const [password, setPassword] = useState('');
    const [doctorId, setdoctorId] = useRecoilState(setDoctorId);
    const [patientId, setPatientId] = useRecoilState(setNewPatientId);
    const [confirmPassword, setConfirmPassword] = useState('');
    const { patientSignUp } = PatientApi();
    const navigate = useNavigate()


    const handleSubmit = (e) => {
        e.preventDefault();
        if (loginData.mobile.length < 10) {
            setIsError('Please enter a valid mobile number.');
            return;
        }
        if (password === '' || confirmPassword === '') {
            setIsError('Password fields cannot be empty.');
            return;
        }
        if (password !== confirmPassword) {
            alert("Passwords don't match.");
            return;
        }
        const bodyData = {
            _id: loginData._id,
            mobile: loginData.mobile,
            password: password,
        };
        patientSignUp(bodyData)
            .then(response => {
                setPatientId(response.data._id);
                const _id = response.data._id;
                navigate(`/appointments/${doctorId}/createprofile/${_id}`);
            })
            .catch(error => {
                // Handle errors
                setIsError('An error occurred during sign-up.');
                console.error('Sign-up error:', error);
});
    };

    return (
        <>
            <lable className='mb-2'>Enter MPIN</lable>
            <div className="col-md-12">
                <MainInput
                    type="password"
                    name="password"
                    maxLength={6}
                    pattern="[+-]?\d+(?:[.,]\d+)?"
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="New MPIN"
                    required
                >
                </MainInput>
            </div>
            <lable className='mb-2'>Confirm MPIN</lable>
            <div className="col-md-12">
                <MainInput
                    type="password"
                    name="password"
                    maxLength={6}
                    pattern="[+-]?\d+(?:[.,]\d+)?"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm MPIN"
                    required>
                </MainInput>
            </div>
            <div className="mr-3" align='right'>
                <MainButtonInput onClick={handleSubmit}>Login</MainButtonInput>
            </div>
        </>
    )
}