import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainInput } from '../../../mainComponent/mainInput';
import { MainButtonInput } from '../../../mainComponent/mainButtonInput';
import axios from 'axios';
import { API } from '../../../config';
import ReportApi from '../../../services/ReportApi';
import { Modal, Button } from "react-bootstrap";

export default function FileUpload(props) {
    const { reportId } = props;
    const [file, setFile] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { getMedicineReport } = ReportApi();

    useEffect(() => {
        getMedicineReportData()
    }, [])

    const handleFileChange = (event) => {
        setFile(event.target.files);
    };

    const openModal = (url) => {
        setSelectedImage(url);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedImage(null);
    };

    const handleUpload = async (e) => {
        e.preventDefault();
        if (!file || file.length === 0) {
            console.error("No files selected.");
            return;
        }

        const formData = new FormData();
        Array.from(file).forEach((singleFile) => formData.append("photo", singleFile));
        try {
            await axios.post(`${API}/documents/${reportId}`, formData, {
                headers: { "Content-Type": "multipart/form-data" }
            });
            alert("file uploaded successfully")
        } catch (error) {
            console.error("Error uploading files", error.response ? error.response.data : error.message);
        }
    };

    const getMedicineReportData = () => {
        getMedicineReport({ reportId })
            .then((res) => {
                setUploadedFiles(res[0]["documents"]);
            })
    }

    return (
        <div>
            <form onSubmit={handleUpload}>
                <h6 align="left" className='mb-2'>Upload Your Documents:</h6>
                <div className='row'>
                    <MainInput
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleFileChange}
                        name="photo"
                        multiple
                    />
                    {uploadedFiles && uploadedFiles.length >= 3 ? (
                        <div className="ml-2 mt-2">
                            <div className="form-group">
                                <input
                                    className="disabled-div"
                                    value="Upload"
                                    type="submit"
                                    disabled={true}
                                />
                            </div>
                            <span>You're hitting a limit for uploading files.</span>
                        </div>)
                        :
                        (<div className="ml-2 mt-2">
                            <MainButtonInput type="submit">Upload</MainButtonInput>
                            <span>You can only upload a maximum of 3 files.</span>
                        </div>)
                    }
                </div>
            </form>
            {/* Uncomment to display uploaded files */}
            {uploadedFiles && uploadedFiles.length > 0 && (
                <div>
                    <h6 align="left" className='mb-2'>Uploaded Files:</h6>
                    <div className='row'>
                        {uploadedFiles.map((url, index) => (
                            <div key={index}>
                                <img
                                    src={url}
                                    alt={`Uploaded file ${index + 1}`}
                                    className='uploadedimg'
                                    onClick={() => openModal(url)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {isModalOpen && (
                <Modal show={isModalOpen} onHide={closeModal}>
                    <Modal.Header closeButton onClick={(e) => e.stopPropagation()}>
                        {/* <button className="prev-btn" onClick={prevImage}>
                            Prev
                        </button>
                        <button className="next-btn" onClick={nextImage}>
                            Next
                        </button> */}
                    </Modal.Header>
                    <Modal.Body>
                        <img src={selectedImage} alt="Selected Image" className="modal-image" />
                    </Modal.Body>
                </Modal>
            )}
        </div>
    );
}
