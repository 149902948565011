import Calender from './Calender'
import { useRecoilState } from 'recoil'
import { doctorIdState } from "../../recoil/selector/doctorIdState"

export default function Dashboard() {
    const [doctorId] = useRecoilState(doctorIdState)

    return (
        <div>
            <Calender doctorId={doctorId} />
        </div >
    )
}  
