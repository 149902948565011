import React from 'react';
import { EditExperience } from "./editExperience";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import { setDoctorExperience } from "../../../../recoil/atom/setDoctorExperience";
import { useRecoilState } from 'recoil';
import ExperienceApi from '../../../../services/ExperienceApi';
import Loader from '../../../Dashboard-card/Loader';
import { Drawer, IconButton, Typography, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function FetchExperience(props) {
    const { doctorId } = props
    const [fetchExperience, setFetchExperience] = useRecoilState(setDoctorExperience)
    const [activeModal, setActiveModal] = useState(false)
    const { fetchExperienceData, removeExperience } = ExperienceApi();
    const [Item, setItem] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [experience, setExperience] = useState([]);
    const isMobile = useMediaQuery('(max-width:768px)')

    useEffect(() => {
        getAllExperience()
    }, [])

    const handleDeleteShow = (item) => {
        setItem(item)
        setShowDelete(true)
    }
    const handleDeleteClose = () => setShowDelete(false)

    const handleClose = () => {
        setActiveModal(false)
        setOpen(false)
    }

    const handleShow = (e, experience) => {
        setExperience(experience)
        setActiveModal(true)
        setOpen(true)
    };

    const EditData = () => {
        handleClose(true);
    };

    const getAllExperience = () => {
        setIsLoading(true);
        fetchExperienceData({ doctorId })
            .then(jsonRes => {
                const exp = manipulateExperience(jsonRes)
                setFetchExperience(exp)
            })
            .catch((error) => {
                console.error("Failed to fetch data", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    function manipulateExperience(data) {
        if (data && data.length > 0) {
            return data.map(function (item, index) {
                const experiences = monthDiff(new Date(item.startYear), new Date(item.endYear))
                const month = experiences % 12;
                let year = 0
                if (experiences > 11) {
                    const exYear = experiences / 12
                    year = exYear.toFixed(0)
                }
                item.totalExperience = `${year}.${month}`;
                return item;
            })
        } else {
            return null
        }

    }

    function monthDiff(start, end) {
        var months;
        months = (end.getFullYear() - start.getFullYear()) * 12;
        months -= start.getMonth();
        months += end.getMonth();
        return months <= 0 ? 0 : months;
    }
    const removeExperienceData = (experience) => {
        const id = experience._id
        removeExperience(id)
            .then(() => {
                getAllExperience();
            })
        handleDeleteClose();
    }

    return (
        <>
            {isLoading ?
                <div className='loader-container'>
                    <Loader />
                </div>
                :
                <>
                    {fetchExperience && fetchExperience.length > 0 ?
                        <div className='row'>
                            {fetchExperience && fetchExperience.map((experience, index) => {
                                return (
                                    <div className='col-md-5' key={index}>
                                        <div >

                                            <div className="row">
                                                <div className="grayBox">
                                                    <div className="row">
                                                        <div className='col-md-9' align='left'>
                                                            <div >
                                                                <span className='font_weight'>
                                                                    Clinic/Hospital Name  -
                                                                </span>
                                                                <span>{experience.clinicName}</span>
                                                            </div>
                                                            <div >
                                                                <span className='font_weight'>
                                                                    Doctor Experience  -
                                                                </span>
                                                                <span>{experience.totalExperience} years</span>
                                                            </div>
                                                            <div >
                                                                <span className='font_weight'>
                                                                    Description  -
                                                                </span>
                                                                <span>{experience.description}</span>
                                                            </div>
                                                            <div >
                                                                <span className='font_weight'>
                                                                    Start Year  -
                                                                </span>
                                                                <span>
                                                                    {new Date(experience.startYear)
                                                                        .toLocaleDateString(undefined,
                                                                            {
                                                                                year: 'numeric', month: '2-digit',
                                                                                timeZone: 'Asia/Kolkata'
                                                                            })}
                                                                </span>
                                                            </div>
                                                            <div >
                                                                <span className='font_weight'>
                                                                    End Year  -
                                                                </span>
                                                                <span>
                                                                    {new Date(experience.endYear)
                                                                        .toLocaleDateString(undefined,
                                                                            {
                                                                                year: 'numeric', month: '2-digit',
                                                                                timeZone: 'Asia/Kolkata'
                                                                            })}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-3' align='right'>
                                                            <Link
                                                                to="#"
                                                                onClick={e => handleShow(e, experience)}
                                                                className="editbutton">
                                                                <i className="icon_pencil-edit"
                                                                    title="Edit profile">
                                                                </i>
                                                            </Link>
                                                            <Link
                                                                to="#"
                                                                onClick={() => handleDeleteShow(experience)}
                                                                className="editbutton">
                                                                <i className="icon-trash-2"
                                                                    title="Delete profile">
                                                                </i>
                                                            </Link>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div> :
                        <div className="clinicHistory font_weight" >Add your experience</div>
                    }
                </>}
            <>
                {isMobile ? (
                    <Drawer anchor="bottom" open={open} onClose={handleClose}>
                        <div className='drawerTitle underline' >
                            <Typography variant="h6">Edit Experience Data</Typography>
                            <IconButton onClick={handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div className='p-4'>
                            <EditExperience
                                doctorId={doctorId}
                                ExId={experience._id}
                                onSubmit={EditData} />
                        </div>
                    </Drawer>
                ) : (
                    <Modal
                        show={activeModal}
                        onHide={handleClose}
                        id={`experience-${experience._id}`} key={experience._id}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Experience Data</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <EditExperience
                                doctorId={doctorId}
                                ExId={experience._id}
                                onSubmit={EditData} />
                        </Modal.Body>
                    </Modal>
                )
                }
            </>
            <div>
                <Modal show={showDelete} onHide={handleDeleteClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Are you sure?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="alert " style={{ backgroundColor: '#e1e8ed', fontSize: 20 }}>You want to delete this experience details.</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" className='appColor' onClick={() => removeExperienceData(Item)}>
                            Yes
                        </Button>
                        <Button variant="default" className='borderStyle' onClick={handleDeleteClose}>
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}
export { FetchExperience }