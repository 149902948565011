import React, { useEffect } from "react"
import { useRecoilState } from "recoil";
import { setPatientMedical } from "../recoil/atom/setPatientMedical";
import PatientApi from "../services/PatientApi";

function FetchPatientMedicalInfo(props) {
    const { patientId } = props;
    const [fetchPatientdata, setFetchPatientData] = useRecoilState(setPatientMedical)
    const { getPatientMedical } = PatientApi()


    useEffect(() => {
        getPatientData()
    }, [])

    function getPatientData() {
        getPatientMedical(patientId)
            .then((result) => {
                setFetchPatientData(result)
            })
    }
    return (
        <>{fetchPatientdata && fetchPatientdata.length > 0 ?

            <>
                {fetchPatientdata.map((item, index) => (
                    <div className="width-32 patientBox mt-2 ml-1 mr-1">
                        <div className="underline">
                            <div className="form_title">
                                <h4>Patient Medical</h4>
                            </div>
                        </div>
                        <div className="patientDataStyle">
                            <div>
                                <label className="font_weight mx-2" >Current Medications :</label>
                                {item.cmedication}
                            </div>
                            <div>
                                <label className="font_weight mx-2" >Past Medications :</label>
                                {item.pmedication}
                            </div>
                            <div>
                                <label className="font_weight mx-2" >Chronic Diseases :</label>
                                {item.diseases}
                            </div>
                            <div>
                                <label className="font_weight mx-2" >Injuries :</label>
                                {item.injuries}
                            </div>
                            <div>
                                <label className="font_weight mx-2" >Surgeries :</label>
                                {item.surgeries}
                            </div>
                        </div>
                    </div>
                ))}
            </>
            :
           null
        }
        </>
    )
}
export { FetchPatientMedicalInfo }